import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';


import { AlertService } from 'src/app/_services/alert.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
	
	  resetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    token: string;
    type: string;
    mail: string;
    reset: boolean;
    isScrollHeader: true;

  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router, private alertService: AlertService, private authenticationService: AuthenticationService) { }
  /**
   * Initial assignment of variables
   */
  ngOnInit() {

  		this.type = this.route.snapshot.paramMap.get("type");

  		this.token = this.route.snapshot.paramMap.get("token");
  		
  	 if(this.type == 'reset' && this.token != '' && this.token != null){
  		this.loading = true;
        this.authenticationService.validateToken(this.token)
            .pipe(first())
            .subscribe(
                data => {
                
                  if(data.status){
                    this.mail = data.result.email;
                    this.reset = true;
                    this.loading = false;
                  }else{
                    this.alertService.error(data.message);
                    this.loading = false;
                  }
                  	
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
  			
  		}

  		

        this.resetForm = this.formBuilder.group({
            password: ['', [Validators.required,     
              Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9]).{7,}')
            ]],
            confirm_password: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirm_password')
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
    get f() { return this.resetForm.controls; }
    /**
     * Handles reset password validation 
     */
    onResetSubmit() {
      this.submitted = true;
        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.resetpwd(this.resetForm.value,this.token,this.mail)
            .pipe(first())
            .subscribe(
                data => {
                  this.loading = false;
                  if(data.status){
                    this.alertService.success(data.message, true);
                    setTimeout(()=>{    
                          this.router.navigate(['/account/login']);
                     }, 2000);
                    
                  }else{
                    this.alertService.error(data.message, true);
                  }
                    
                    
                    
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

}
