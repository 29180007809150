import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { ListsearchService } from 'src/app/_services/listsearch.service';
import {constantVariables} from 'src/scripts/constants';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-listpage',
  templateUrl: './listpage.component.html',
  styleUrls: ['./listpage.component.scss']
})

export class ListpageComponent implements OnInit {
	searchText:string;
	checkbox1:boolean;
	checkbox2:boolean;
	checkbox3:boolean;
	isCleared:boolean;
	serviceType='';
	loading=false;
	poolDataList={total:null};
	currentUser:object;
	poolList=[];
	poolArray=[];
	submitted=false;
	poolSearch = { searchText: '',}; 
	apiUrl:string;
	showMapView=true;
	gridListShow:boolean;
	poolSearchForm: FormGroup;
	filterForm: FormGroup;
	currentPage=1;
	allPool=0;
	mapLat = 33.6009389;
	mapLng = 86.95610590000001;
	origin = { lat: 24.799448, lng: 120.979021 };
	destination = { lat: 24.799524, lng: 120.975017 };
	userCurrentLocation={lat:'',lng:''};
	poolFilterType = constantVariables.POOLFILTERTYPE;
	customerUserType = constantVariables.CUSTOMERUSERTYPE;
	spUserType = constantVariables.SPUSERTYPE;
	order = ['distance'];
	poolCreated:boolean;
	filterData={serviceType:''}
	itemsPerPage=5;
	totalItems:number;
	serviceTypes = constantVariables.SERVICEPROVIDERS;
	showRatingFilter=false;
	ratingsForm:FormGroup;
	ratingArray = [{ id: 5, checked: false }, { id: 4, checked: false }, { id: 3, checked: false }, { id: 2, checked: false } , { id: 1, checked: false }];
	ratingValues=[];
	sortByValue='2';
	defaultFilter=false;
	public show:boolean = false;
	 public buttonName:any = 'Read More';
	constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: ActivatedRoute, private alertService: AlertService, private ListsearchService: ListsearchService, private orderPipe: OrderPipe) { }


    ngOnInit() {
			this.filterData={serviceType:''}
			this.apiUrl = `${environment.apiUrl}`;
			this.userCurrentLocation = JSON.parse(localStorage.getItem('userCurrentLocation'));
			this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
			var searchData=JSON.parse(localStorage.getItem('searchItem'));
			if (this.currentUser){
				var poolcreation = JSON.parse(localStorage.getItem('currentUser')).poolCreated;
				this.poolCreated = poolcreation;
			}
			if (searchData){
				this.searchText = searchData.formatted_address;
				this.serviceType = searchData.serviceType;
				this.filterData.serviceType = this.serviceType;
				this.poolSearch.searchText = searchData.searchText;
			}else{
				this.allPool = 1;
			}
			this.defaultFilter=true;
			this.getPoolList(this.searchText,this.serviceType);
			this.poolSearchForm = this.formBuilder.group({
				searchText: ['', [Validators.required]],
			});
			this.filterForm = this.formBuilder.group({
				serviceType: ['']
			});
			localStorage.removeItem('searchItem');
			localStorage.removeItem('bookingDetails');
			this.ratingsForm = this.formBuilder.group({
				ratingValues: this.formBuilder.array([]),
			});
    }
    clearValue(){
	this.isCleared=false;
	
	}
	get f() { return this.poolSearchForm.controls; }
	/** api call for get pool list detail page  */
    getPoolList(text,type){
		this.loading = true;
		var searchData = { userData: this.currentUser,searchInput: text, serviceType: type, page: this.currentPage,all:this.allPool,ratingValues:this.ratingValues,
			sortBy: this.sortByValue, lat: this.userCurrentLocation && this.userCurrentLocation.lat ? this.userCurrentLocation.lat : null, lng: this.userCurrentLocation && this.userCurrentLocation.lng? this.userCurrentLocation.lng:null,
			defaultFilter:this.defaultFilter};
	    this.ListsearchService.searchPool(searchData)
	      .pipe(first())
	      .subscribe(
	          data => {
				this.loading = false;
				if (data && data.code===constantVariables.CONSTONE){
					this.poolDataList = data.result.poolList;
					this.poolList = data.result.poolList.data;
					this.itemsPerPage = this.itemsPerPage;
					this.totalItems =  this.poolDataList.total;
					this.poolCreated = data.result.poolCreated;
					this.submitted=false;
					this.showMap(this.poolList[0]);
					// if (this.userCurrentLocation && this.userCurrentLocation.lat && this.userCurrentLocation.lng){
					// 	this.poolList.forEach(item => {
					// 		const distance = this._getDistanceFromLatLonInKm(this.userCurrentLocation.lat, this.userCurrentLocation.lng, item.lat, item.lng);
					// 		item.distance = distance;
					// 	});
					// 	this.poolArray = this.orderPipe.transform(this.poolList, this.order);
					// 	this.showMap(this.poolArray[0]);
					// }
					//localStorage.removeItem('searchItem');
	            }else{
					this.alertService.error(constantVariables.APIRESPONSEERR);
				}
	          },
	          error => {
				  this.loading = false;
				  this.alertService.error(constantVariables.APIRESPONSEERR);
	    });
	}
	/** listing layout change  */
	showGridList(type){
		this.gridListShow = type === 1? true:false;
		this.showMapView = type === constantVariables.CONSTTHREE? true:false;
	}
	/**search form submit  */
	onSubmit(){
		this.submitted=true;
		this.allPool=0;
		if(this.poolSearchForm.invalid){
			return;
		}
		this.getPoolList(this.poolSearchForm.value.searchText, this.filterData.serviceType);

	}
	/** page change api call  */
	onPageChange(page){
		this.currentPage=page;
		this.getPoolList(this.poolSearchForm.value.searchText, this.filterData.serviceType);
	}
	/** change filter type api call  */
	setFilterType(type){
		this.allPool = type;
		this.poolSearch.searchText = '';
		if (this.allPool===constantVariables.CONSTONE){
			this.poolSearchForm.value.searchText='';
			this.filterData.serviceType='';
			this.defaultFilter=false;
			this.getPoolList('', this.serviceType);
		}
	}
	/** set  favourite  api call  */
	setMyFavorite(pool){
		if (pool && this.currentUser){
			let data={poolId:pool.id,userId:this.currentUser}
			this.loading = true;
			this.ListsearchService.setPoolFavorite(data)
				.pipe(first())
				.subscribe(
					data => {
						this.loading = false;
						if (data && data.code===constantVariables.CONSTONE) {
							
							let favouirteResult=data.result.favourite;
							let index = this.poolList.findIndex(record => record.id===favouirteResult.poolServiceId);
							this.poolList[index].favouriteStatus = favouirteResult.status;
						}else{
							this.alertService.error(constantVariables.APIRESPONSEERR);
						}
					},
					error => {
						this.loading = false;
						this.alertService.error(constantVariables.APIRESPONSEERR);
					});
		}
	}
	/** service provider type name dipslay  */
	getTypeName(type){
		let index = constantVariables.SERVICEPROVIDERS.find(record => record.id === type);
		return index.name;
	}

	/** to Show view map  */
	showMap(pool){
		this.showMapView = true;
		if (pool){
			this.mapLat = parseFloat(pool.lat);
			this.mapLng = parseFloat(pool.lng);
			this.showMapView = true;
		}
	}
	/** set local value for route direction  */
	showDirection(pool){
		let address = { 'address': pool.street + ',' + pool.city.city + ',' + pool.states.state + ',' + pool.zipCode + ',' +constantVariables.COUNTRY};
		localStorage.setItem('mapDirectionDestination', JSON.stringify(address));
	}
	/** clear filetrs  */
	clearFilters(){
		this.checkbox1=false;
		this.checkbox2=false;
		this.checkbox3=false;
		this.poolSearch.searchText = '';
		this.filterForm.value.serviceType = '';
		this.filterData.serviceType = '';
		this.allPool =1;
		this.serviceType='';
		this.ratingValues=[];
		this.resetAllCheckBox();
		this.sortByValue='2';
		this.defaultFilter = true;
		this.showRatingFilter=false;
		localStorage.removeItem('searchItem')
		this.getPoolList('', '');
	}
	/**
	 * calcualte distance between two coordinates
	 * @param lat1 
	 * @param lon1 
	 * @param lat2 
	 * @param lon2 
	 */
	_getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
		var R = 6371; // Radius of the earth in kilometers
		var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
		var dLon = this.deg2rad(lon2 - lon1);
		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c; // Distance in KM
		return d;
	}

	deg2rad(deg) {
		return deg * (Math.PI / 180)
	}

	/**
	 * 
	 */
	serviceChange(event){
		this.allPool = 0;
		this.defaultFilter = false;
		this.filterData.serviceType = event.target.value;
		this.getPoolList(this.poolSearchForm.value.searchText, event.target.value);
	}

	/**
	 * search by ratings
	 */
	searchByRatings(){
		this.ratingValues=this.ratingsForm.value.ratingValues;
		this.allPool = 0;
		this.defaultFilter = false;
		this.sortByValue = '2';
		this.showRatingFilter=false;
		this.getPoolList(this.poolSearchForm.value.searchText, this.filterData.serviceType);
	}

	/**
	 * Handles rating checked or unchecked status
	 * @param option
	 * @param event
	 */

	updateCheckedOptions(option, event) {
		const isChecked = event.target.checked;
		const serviceProvidedArray = <FormArray>this.ratingsForm.controls.ratingValues;
		if (isChecked) {
			option.checked=true;
			serviceProvidedArray.push(new FormControl(option.id));
		} else {
			let i: number = 0;
			serviceProvidedArray.controls.forEach((ctrl: FormControl) => {
				if (ctrl.value == option.id) {
					option.checked = false;
					serviceProvidedArray.removeAt(i);
					return;
				}
				i++;
			});
		}
	}
	/**
	 * uncheck all the rating check box
	 */
	resetAllCheckBox(){
		this.ratingArray.forEach(item=>{
			item.checked=false;
			const serviceProvidedArray = <FormArray>this.ratingsForm.controls.ratingValues;
			let i: number = 0;
			serviceProvidedArray.controls.forEach((ctrl: FormControl) => {
				if (ctrl.value == item.id) {
					item.checked = false;
					serviceProvidedArray.removeAt(i);
					return;
				}
				i++;
			});
		});
	}
	/**
	 * sorting Change  api call 
	 */
	sortingChange(event){
		this.sortByValue =  event.target.value;
		this.allPool = 0;
		this.ratingValues=[];
		this.defaultFilter = false;
		this.getPoolList(this.poolSearchForm.value.searchText, this.filterData.serviceType);
	}
	/**
	*Read More/Less Description
	*/
	toggle(pool){
		 pool.show = !pool.show;
		}
	
}
