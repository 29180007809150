// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const constantVariables = {
  SERVICEPROVIDERS : [{id:1,name:'Installation',checked: false},{id:2,name:'Maintenence',checked: false},{id:3,name:'Repair',checked: false}],
  CANCELPOLICIES: [{ id: 1, name: 'Relax', checked: false, description: 'Can cancel up to 3 days before rental time' }, { id: 2, name: 'Moderate', checked: false, description: 'Can cancel up to 24 hours before rental time' }, { id: 3, name: 'Strict', checked: false, description: 'Can cancel up to 30 minutes before rental time ' }],
  POOLFILTERTYPE: [{ id: 1, name: 'All', checked: false, idName: 'all' }, { id: 2, idName: "doctors", name: 'Docters', checked: false }, { id: 3, idName:"clinics",name:'Clinics',checked: false}],
  CONSTONE:1,
  CONSTZERO:0,
  CONSTTWO:2,
  CONSTTHREE:3,
  CONSTFOUR: 4,
  CONSTTEN: 10,
  TENTHOUSAND:10000,
  BILLION:1000000000,
  IMAGEREQMSG:'Please upload image',
  COVERIMAGEMSG:'Only one file Allowed',
  IMAGETYPEMSG:'Please upload png, jpg, jpeg file',
  SERVICEIMAGEMSG:'Maximum 10 files allowed',
  REVIEWIMAGEMSG:'Maximum 5 files allowed',
  GOOGLEAPIKEY:'AIzaSyBUEQo2wOlFybL-S3Y-ZQfsd407CcpnIrc',
  COUNTRY:'USA',
  APIRESPONSEERR:'Something went wrong',
  SERVICEIMAGEPATH:'/uploads/serviceimage/',
  COVERIMAGEPATH:'/uploads/coverimage/',
  REVIEWIMAGEPATH:'/uploads/reviewimage/',
  PROFILEIMAGEPATH:'/profile/',
  DOLLAR:'$',
  COUNTRYID:1,
  CUSTOMERUSERTYPE:3,
  SPUSERTYPE: 2,
  CANCELSTATUS:[{id:5,bookingStatus:'Cancelled'}],
  BOOKINGSTATUS: [{ id: 1, name: 'New' }, { id: 2, name: 'In Progress' }, { id: 3, name: 'Completed' }, { id: 4, name: 'Close' }],
  BOOKINGTYPEOPTIONS: [{ id: 1, name: 'Flat rate', checked: false}, { id: 2, name: 'Hourly Rate', checked: false}],
  EXPYEAR: 2030,
  EXPMONTH: [{ id: 1, value: 'January' }, { id: 2, value: 'February' }, { id: 3, value: 'March' }, { id: 4, value: 'April' }, { id: 5, value: 'May' }
    , { id: 6, value: 'June' }, { id: 7, value: 'July' }, { id: 8, value: 'August' }, { id: 9, value: 'September' }, { id: 10, value: 'October' }
    , { id: 11, value: 'November' }, { id: 12, value: 'December' }],
  CURRENCY:'USD',
  EXPSTARTYEAR: 2019
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
