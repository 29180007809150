import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FootermenuService {

  constructor(private http: HttpClient) { }

  footerMenu(id) {  		
        var url = `${environment.apiUrl}/api/footerMenu/${id}`;
        return this.http.get<any>(url)
            .pipe(map(data => {
                return data;
            }));
    }
}
