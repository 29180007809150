import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(private http: HttpClient) { }

  checkreviews(id)
  {
  	var url = `${environment.apiUrl}/api/booking/checkReviews/${id}`;
    return this.http.get<any>(url)
      .pipe(map(data => {
        return data;
      }));
  }
  /**
  *  Rating & Review
  */
   userReview(reviewData){
     console.log(reviewData)
    var url = `${environment.apiUrl}/api/booking/userReview`;
    const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'multipart/form-data',
                'Authorization':'Bearer ' + localStorage.currentUser
            }),
            observe: 'response' as 'body'
        };
    const formData = new FormData();
    formData.append('poolServiceId', reviewData.poolId);
    formData.append('userId', reviewData.userId);
    formData.append('bookingId',reviewData.BookingId);
    formData.append('rating',reviewData.currentRate);
    formData.append('description',reviewData.comments);
    formData.append('reviewImage',reviewData.file[0]);
    var reviewImages=reviewData.file;
    for (var x = 0; x < reviewImages.length; x++) {
        formData.append("reviewImage[]", reviewImages[x]);
    }
    var reviewFileDeleteUrl = reviewData.reviewFileDeleteUrl;
    for (var x = 0; x < reviewFileDeleteUrl.length; x++) {
        formData.append("reviewFileDeleteUrl[]", reviewFileDeleteUrl[x]);
    }
    return this.http.post<any>(url, formData,  httpOptions ) .pipe(map(data => {
                return data;
        }));
  }
}
