import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OwlModule } from 'ngx-owl-carousel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ListpageComponent } from './listpage/listpage.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AlertComponent } from './alert/alert.component';
import { TokenInterceptor } from './_helpers/token.interceptor';
import { PasswordComponent } from './password/password.component';
import { ProfileComponent } from './profile/profile.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ListmyserviceComponent } from './listmyservice/listmyservice.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ManagemybookingComponent } from './managemybooking/managemybooking.component';
import { BookserviceComponent } from './bookservice/bookservice.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxPaginationModule } from 'ngx-pagination';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { PoolmapdirectionComponent } from './poolmapdirection/poolmapdirection.component';
import { PooldetailComponent } from './pooldetail/pooldetail.component';
import { constantVariables } from '../scripts/constants';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Timeconvert } from './_pipes/app.timeConvert';
import { TruncatePipe  } from './_pipes/app.limitpipe';
import { OrderModule } from 'ngx-order-pipe';
import { PaymentComponent } from './payment/payment.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ErrorComponent } from './error/error.component';
import { FootermenuComponent } from './footermenu/footermenu.component';
@NgModule({
    declarations: [
        Timeconvert,
        TruncatePipe,
        AppComponent,
        NavbarComponent,
        HomepageComponent,
        ListpageComponent,
        LoginComponent,
        RegisterComponent,
        AlertComponent,
        PasswordComponent,
        ProfileComponent,
        ListmyserviceComponent,
        ManagemybookingComponent,
        BookserviceComponent,
        PoolmapdirectionComponent,
        PooldetailComponent,
        PaymentComponent,
        ReviewsComponent,
        ErrorComponent,
        FootermenuComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        OwlModule,
        ModalModule.forRoot(),
        ImageCropperModule, NgxFileDropModule, GooglePlaceModule,
        NgxPaginationModule,
        AgmCoreModule.forRoot({
            apiKey: constantVariables.GOOGLEAPIKEY,
            libraries: ['geometry']
        })
        , AgmDirectionModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxMaterialTimepickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserAnimationsModule,
        OrderModule

    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
