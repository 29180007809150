import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { constantVariables } from 'src/scripts/constants';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListsearchService } from 'src/app/_services/listsearch.service';
@Component({
  selector: 'app-poolmapdirection',
  templateUrl: './poolmapdirection.component.html',
  styleUrls: ['./poolmapdirection.component.scss']
})
export class PoolmapdirectionComponent implements OnInit {
  origin = { };
  destination = { }; 
  searchForm: FormGroup;
  mapLat = 33.6009389;
  mapLng = 86.95610590000001;
  showMap=false;
  loading=false;
  destinationPoint:'';
  constructor(private formBuilder: FormBuilder, private ListsearchService: ListsearchService, private route: ActivatedRoute, private router: ActivatedRoute, private alertService: AlertService) { }

  ngOnInit() {
    let destinationAdd = JSON.parse(localStorage.getItem('mapDirectionDestination'));
    let address = { 'address': destinationAdd.address };
    this.destinationPoint = address.address;
    this.ListsearchService.mapCoordinates(address)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            let mapLat = data.result.geocoder.lat;
            let mapLng = data.result.geocoder.lng;
            this.destination = { lat: mapLat, lng: mapLng };
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
      this.searchForm = this.formBuilder.group({
        searchInput: [''],
        destinationPoint:['']
      });
  }
  /**
   * Pool search submit
   */
  onSubmit(){
    if (this.searchForm.invalid){
      return;
    }
    this.loading = true;
    let address = { 'address': this.searchForm.value.searchInput };
    this.ListsearchService.mapCoordinates(address)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            let mapLat = data.result.geocoder.lat;
            let mapLng = data.result.geocoder.lng;
            this.origin = { lat: mapLat, lng: mapLng};
            this.showMap=true;
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

}
