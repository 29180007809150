import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'time12'
})
export class Timeconvert implements PipeTransform {
   transform(val : string) : string {
   	var timeString = val;
	var H = +timeString.substr(0, 2);
	var h = (H % 12) || 12;
	var ampm = H < 12 ? " AM" : " PM";
    var timeString =h + timeString.substr(2, 3) + ampm;
    return timeString;
   }
}