import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PasswordComponent } from './password/password.component';
import { ProfileComponent } from './profile/profile.component';
import { ListmyserviceComponent} from './listmyservice/listmyservice.component';

import { ManagemybookingComponent } from './managemybooking/managemybooking.component';
import { PooldetailComponent } from './pooldetail/pooldetail.component';
import { AuthGuard  } from './_guards/auth.guard';
import { BookserviceComponent } from './bookservice/bookservice.component';
import { ListpageComponent} from './listpage/listpage.component';
import { PoolmapdirectionComponent } from './poolmapdirection/poolmapdirection.component';
import { PaymentComponent } from './payment/payment.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ErrorComponent } from './error/error.component';
import { FootermenuComponent } from './footermenu/footermenu.component';
const routes: Routes = [
    { path: '', component: HomepageComponent },
    {
        path: 'account/:type',
        component: LoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'password/:type',
        component: PasswordComponent
    },
    {
        path: 'password/:type/:token',
        component: PasswordComponent
    },
    {
        path: 'account/:type/:token/:mail',
        component: LoginComponent
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'profile/:type',
        component: ProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'listmyservice',
        component: ListmyserviceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'listmyservice/:poolId',
        component: ListmyserviceComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'bookservice',
        component: BookserviceComponent

    },
    {
        path: 'checkout/:id',
        component: PaymentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'bookservice/:bookingId',
        component: BookserviceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'managemybooking',
        component: ManagemybookingComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'poollist',
        component: ListpageComponent,

    },
    {
        path: 'poolmap',
        component: PoolmapdirectionComponent,
    },
    {
        path: 'pooldetail/:id',
        component: PooldetailComponent,
    },
    {
        path: 'writereview/:id',
        component: ReviewsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: 'footermenu/:id',
        component: FootermenuComponent,
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
