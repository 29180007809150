import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
/**
 * Get user details 
 */
  getUser(userData) {
        
      var url = `${environment.apiUrl}/api/get_user/${userData}`;

      return this.http.get<any>(url)
            .pipe(map(data => {

                return data;
            }));
    }

/**
 * Upload profile image from user account
 * @param img 
 */
    imgUpload(img){
    	var url = `${environment.apiUrl}/api/profile_img_upload`;

        return this.http.post<any>(url,{'img':img})
            .pipe(map(data => {
              console.log(data)
                return data;
            }));
    }

/**
 * Update user account details from profile
 * @param user 
 */
    update(user: User) {

        var url = `${environment.apiUrl}/api/update_user`;

        return this.http.post<any>(url, user)
            .pipe(map(data => {

                return data;
            }));
    }
    /**
     * Handles value decryption 
     */
    fetchValue(data) {
        var url = `${environment.apiUrl}/api/booking/decryptValue/${data}`;

        return this.http.get<any>(url)
            .pipe(map(data => {
                return data;
            }));
    }

}
