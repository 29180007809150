import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagemybookingService {
  constructor(private http: HttpClient) { 
     
     
  }

  	bookingList(searchText,currentUser) {
        var url = `${environment.apiUrl}/api/bookingList`;
        var searchDatas = { searchText:searchText,id:currentUser.id,usertype:currentUser.usertype};
        return this.http.post<any>(url,searchDatas)
            .pipe(map(data => {
                return data;
            }));
   	}

   	deleteBooking(id){
   		var url = `${environment.apiUrl}/api/deleteBooking/${id}`;
        return this.http.delete<any>(url)
            .pipe(map(data => {
                return data;
            }));
   	}

}
