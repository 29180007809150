import { Component, OnInit , TemplateRef } from '@angular/core';
import {PooldetailService} from 'src/app/_services/pooldetail.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl,FormArray } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { constantVariables } from 'src/scripts/constants';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BookingsService } from 'src/app/_services/bookings.service';
import { AlertService } from 'src/app/_services/alert.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
@Component({
  selector: 'app-pooldetail',
  templateUrl: './pooldetail.component.html',
  styleUrls: ['./pooldetail.component.scss']
})

export class PooldetailComponent implements OnInit {
currentUser={};
/*currentUserType:number;*/
customerUserType = constantVariables.CUSTOMERUSERTYPE;
loading = false;
reviewForm: FormGroup;
poolDetail={pool_service_type_id:[]};
reviewDetail=[];
bookingList=[];
city=[];
states=[];
serviceProvided=[];
poolserviceimages=[];
cancelPolicy=[];
review=[];
reviewAverage:number;
oneStarData=[];
twoStarData=[];
threeStarData=[];
fourStarData=[];
fiveStarData=[];
cancelPolicies=constantVariables.CANCELPOLICIES;
serviceimagepath=environment.apiUrl+constantVariables.SERVICEIMAGEPATH;
coverimagepath=environment.apiUrl+constantVariables.COVERIMAGEPATH;
reviewimagepath=environment.apiUrl+constantVariables.REVIEWIMAGEPATH;
profileimagepath=environment.apiUrl+constantVariables.PROFILEIMAGEPATH;
lat: number;
lng: number;
zoom: number;
id:string;
geocoder={lat:null,lng:null};
countryName=constantVariables.COUNTRY;
bookingForm:FormGroup;
dropdownSettings = {
  singleSelection: false,
  idField: 'id',
  textField: 'name',
  itemsShowLimit: 3,
};
submitted=false;
todayDate=new Date();
dateErr = false;
timeErr = false;
servicesErr = false;
  notesErr=false
bookingData={bookingTime:null,bookingDate:null,services:[],notes:''}
bookingStorageData = { bookingDate: '', bookingTime: '', services: [], notes:''};
serviceProviders=constantVariables.SERVICEPROVIDERS;
checkBox=[];
apiUrl: string;
onestar:number;
twostar:number;
threestar:number;
fourstar:number;
fivestar:number;
reviewButton:boolean;
reviewStatus:[{review_status:[]}];
currentUserId:{};
reviewData={id:''};
reviewImage=[];
maxReviewFileError=false;
reviewFormErr=false;
reviewCommentsError=false;
reviewErrMsg="";
reviewFileUrl=[];
reviewFile=[];
reviewFileDeleteUrl=[];
reviewSubmitted=false;
popupMessage='';
modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
username='';
profilePic='';
profile_image='';
fullname='';
bookingId='';
pool_service_type_id=[];
  constructor(private pooldetailService:PooldetailService,private route: ActivatedRoute,private formBuilder: FormBuilder,
    private router: Router, private sanitizer: DomSanitizer, private formbuilder: FormBuilder, private modalService: BsModalService, private BookingsService: BookingsService, private alertService: AlertService) { }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    this.reviewButton=false;
    this.currentUser=JSON.parse(localStorage.getItem('currentUser'));
    /*this.currentUserType = JSON.parse(localStorage.getItem('currentUser')).user_type;*/
    this.bookingStorageData = JSON.parse(localStorage.getItem('bookingDetails'));
    this.bookingForm = this.formbuilder.group({
      bookingDate: ['', [Validators.required]],
      bookingTime: ['', [Validators.required]],
      services: this.formBuilder.array([]),
      notes: ['', [Validators.maxLength(100)]],
    });

     this.reviewForm = this.formBuilder.group({
      comments:['', [Validators.required,Validators.maxLength(1000)]],
      currentRate:['', [Validators.required]]
    });

    this.serviceProviders = constantVariables.SERVICEPROVIDERS;
    if(this.currentUser)
    {
      this.profilePic = JSON.parse(localStorage.getItem('currentUser')).profile_img;
       this.fullname = JSON.parse(localStorage.getItem('currentUser')).full_name;
       this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
    var data={id:this.id,userId:this.currentUser}
      this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
      this.profilePic = JSON.parse(localStorage.getItem('currentUser')).profile_img;
      this.fullname = JSON.parse(localStorage.getItem('currentUser')).full_name;
    }
    this.reviewForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(1000)]],
      currentRate: ['', [Validators.required]]
    });
    this.getPoollist(data);
    this.assignBookingValues();


  }
  get ab() { return this.bookingForm.controls; }
  get f() { return this.reviewForm.controls; }
  /** to get pool List*/
  getPoollist(id){
    this.loading = true;
    this.pooldetailService.poolList(this.id)
      .pipe(first())
      .subscribe(
          data => {
            this.loading = false;
            if(data.status){
              this.poolDetail=data.result.poolList[0];
              this.reviewDetail= data.result.reviews;
              var average=data.result.reviewsAverage;
              this.reviewAverage=Math.round(average*10)/10;
              this.city=data.result.poolList[0].city;
              this.states=data.result.poolList[0].states;
              this.serviceProvided=data.result.poolList[0].serviceProvided;
              this.poolserviceimages = data.result.poolList[0].poolserviceimages;
              this.geocoder = data.result.geocoder;
              this.bookingList=data.result.bookingList;

              var arealistArray=[];
              this.poolDetail.pool_service_type_id.forEach((elem1, index) => {elem1;
                this.serviceProviders.forEach((elem2, index) => {elem2;
                  if(elem1.poolServiceType=== elem2.id)
                  {
                    arealistArray.push(elem2);
                  }
                });
              });
              this.checkBox=arealistArray;
              console.log('poolDetail',this.poolDetail)
              this.cancelPolicy=data.result.poolList[0].cancel_policy_type;
              this.setCurrentLocation();
              if(this.currentUser)
              {
                this.getReviewStatus();
              }
              if(this.reviewDetail) {
              var oneStarData =  this.reviewDetail.filter(function(user) {
                  if(user.rating==1)
                  {
                     return user;
                  }
              });
              var twoStarData =  this.reviewDetail.filter(function(user) {
                  if(user.rating==2)
                  {
                     return user;
                  }
              });
              var threeStarData =  this.reviewDetail.filter(function(user) {
                  if(user.rating == 3)
                  {
                     return user;
                  }
              });
              var fourStarData =  this.reviewDetail.filter(function(user) {
                  if(user.rating==4)
                  {
                     return user;
                  }
              });
              var fiveStarData =  this.reviewDetail.filter(function(user) {
                  if(user.rating==5)
                  {
                     return user;
                  }
              });
              }
              if (oneStarData) {
                this.onestar=oneStarData.length/this.reviewDetail.length*100;
              }
              if (twoStarData) {
                this.twostar=twoStarData.length/this.reviewDetail.length*100;
              }
              if (threeStarData) {
                this.threestar=threeStarData.length/this.reviewDetail.length*100;
              }
              if (fourStarData) {
                this.fourstar=fourStarData.length/this.reviewDetail.length*100;
              }
              if (fiveStarData) {
                this.fivestar=fiveStarData.length/this.reviewDetail.length*100;
              }
            }
          },
          error => {
              this.loading = false;
    });
  }

  private setCurrentLocation() {
      this.lat = this.geocoder.lat;
      this.lng = this.geocoder.lng;
      this.zoom = 15;
    }
	getReviewStatus()
  {
    var data={poolId:this.id,userId:this.currentUser}
    this.pooldetailService.getReviewStatus(data)
    .pipe(first())
      .subscribe(
          data => {
            console.log('data',data)
            this.loading = false;
            this.bookingId=data.result.reviewData[0].id;
            var reviewData = data.result.reviewData;
            if(reviewData && reviewData!=0)
            {
              this.reviewStatus=data.result.reviewData[0];
              this.reviewStatusCheck(this.reviewStatus)
            }
            /*if(this.reviewStatus && this.reviewStatus.length !=0)
            {
              this.reviewButton=false;
            }else
            {
              this.reviewButton=true;
            }*/
          },
          error => {
              this.loading = false;
    });
  }
  carouselOptions = {
        center: true,
        items: 1,
        loop: true,
        dots: false,
        nav    : true,
        navText : ["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        responsive: {
            0: { items: 1 },
            600: { items: 1},
            1000: { items: 1 }
        }
    }
  /**
   * Get service provider name to display
   * @param type
   */
  getTypeName(type) {
    let index = constantVariables.SERVICEPROVIDERS.find(record => record.id === parseInt(type));
    return index.name;

  }

  /**
   * Handles dropdown multi selet
   *
   */
  updateCheckedOptions(option, event) {
    const isChecked = event.target.checked;
    const serviceProvidedArray = <FormArray>this.bookingForm.controls.services;
    if (isChecked) {
      this.servicesErr = false;
      serviceProvidedArray.push(new FormControl(option));
    } else {
      let i: number = 0;
      serviceProvidedArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value.id == option.id) {
          serviceProvidedArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
/**
 * Adjust booking details value assignment
 */

  assignBookingValues() {
    if (this.bookingStorageData){
      this.bookingData = { bookingTime: this.bookingStorageData.bookingTime,
        bookingDate: this.bookingStorageData.bookingDate,
        notes: this.bookingStorageData.notes, services: this.bookingStorageData.services
      }
      for (var i = 0; i < this.serviceProviders.length; i++) {
        let index = this.bookingStorageData.services.findIndex(record => record.id === this.serviceProviders[i].id);
        if (index >=0 && this.serviceProviders && this.bookingStorageData.services && this.bookingStorageData.services.length > 0 &&
          this.bookingStorageData.services[index].id === this.serviceProviders[i].id) {

          this.serviceProviders[i].checked = true;
          const serviceProvidedArray = <FormArray>this.bookingForm.controls.services;
          serviceProvidedArray.push(new FormControl(this.serviceProviders[i]));
        } else {
          this.serviceProviders[i].checked = false;
        }
      }
    }else{
      this.serviceProviders.forEach(item => {
        item.checked=false;
      });
    }

  }
  /**
   * Handles booking form  submit
   */
  onSubmit(poolDetail) {
    this.submitted = true;
    if(!this.bookingForm.value.bookingDate){
      this.dateErr=true;
    }else{
      this.dateErr = false;
    }
    if (!this.bookingForm.value.bookingTime) {
      this.timeErr = true;
    }else{
      this.timeErr = false;
    }
    if (!this.bookingForm.value.services || this.bookingForm.value.services.length<1) {
      this.servicesErr = true;
    }else{
      this.servicesErr = false;
    }
    if (this.bookingForm.value.notes && this.bookingForm.value.notes.length > 100) {
      this.notesErr = true;
    } else {
      this.notesErr = false;
    }

    if (!this.servicesErr && !this.timeErr && !this.dateErr && !this.notesErr){
      var data = { bookingDate: this.bookingForm.value.bookingDate,
        bookingNew:true,
        bookingTime: this.bookingForm.value.bookingTime,
        services: this.bookingForm.value.services,
        notes: this.bookingForm.value.notes,
        poolId: poolDetail.id, poolName: poolDetail.serviceName,
        poolOpening: poolDetail.poolOpening, poolClosing: poolDetail.poolClosing,
        poolOpeningType: poolDetail.poolOpeningType, poolClosingType: poolDetail.poolClosingType,
        maintenanceType: poolDetail.maintenanceType,
        maintenance: poolDetail.maintenance, cancelPolicy: poolDetail.cancel_policy_type,poolServiceTypes:poolDetail.pool_service_type_id};
        localStorage.setItem('bookingDetails',JSON.stringify(data));
        this.router.navigate(['bookservice']);
    }else{
      return;
    }
  }

  /**
  * Write A Review in detail page 
  */

   writeReviewPopup(template: TemplateRef<any>, id){
    this.popupMessage = 'Rate & Review';
    this.username =  this.fullname;
    if(this.profilePic && this.profilePic !=null){
       this.profile_image =environment.apiUrl+this.profilePic;
    }else{
        this.profile_image = '/assets/images/default-user-profile-image.png';
    }
    this.modalRef = this.modalService.show(template, this.config);
  }

  //** review Check

  reviewStatusCheck(reviewStatus)
  {
    if(reviewStatus && reviewStatus.review_status && reviewStatus.review_status.length ==0)
    {
    this.reviewButton=true;
    }else if(reviewStatus && reviewStatus.review_status && reviewStatus.review_status[0].reviewStatus==2)
    {
    this.reviewButton=true;
    }else
    {
    this.reviewButton=false;
    }
  }

/**
  * Rating And Review
  **/
  reviewRating(form)
  {
    this.reviewSubmitted = true;
    this.maxReviewFileError = false;
    this.reviewFormErr=false;
    this.reviewCommentsError = false;
    if (!this.reviewFileUrl || this.reviewFileUrl.length<= constantVariables.CONSTZERO){
      this.maxReviewFileError=true;
      this.reviewErrMsg=constantVariables.IMAGEREQMSG;
    }
    if (this.reviewFileUrl.length <= constantVariables.CONSTZERO){
      this.maxReviewFileError = true;
      this.reviewErrMsg = 'Please upload image';
    }
    if (!this.reviewForm.value.comments){
      this.reviewCommentsError=true;
    }
    if(!this.reviewForm.value.currentRate)
    {
      this.reviewFormErr=true;
    }
    if (this.reviewForm.invalid) {
      return;
    }
    if(!this.maxReviewFileError && !this.reviewFormErr)
    {
      this.loading = true;
     var data ={poolId:this.id,BookingId:this.bookingId,userId:this.currentUserId, comments:this.reviewForm.value.comments,currentRate:this.reviewForm.value.currentRate,file:this.reviewFile,reviewFileDeleteUrl: this.reviewFileDeleteUrl}
      this.BookingsService.userReview(data)
    .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE || data.body.code === constantVariables.CONSTONE) {
            window.scrollTo(0, 0)
            this.alertService.success(data.body.result.message);
          }else{
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
          this.modalRef.hide();
        },
        error => {
          this.loading = false;
        });
    }
  }


  /**
   * Handles drag and drop of review image  file
   */
  public files: NgxFileDropEntry[] = [];
  public reviewFiles: NgxFileDropEntry[] = [];

  /**
   * Handles drag and drop of service iamge  file
   */

  public reviewFileDropped(files: NgxFileDropEntry[]) {
     this.maxReviewFileError=false;
    if(this.reviewFiles && this.reviewFiles.length<5){
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if(file.type==='image/png' || file.type==='image/jpeg' || file.type==='image/jpg' || file.type==='image/gif'){
                const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
                if(this.reviewFiles && this.reviewFiles.length<5){
                  this.reviewFiles.push(droppedFile );
                  this.reviewFileUrl.push({ url: url});
                  this.reviewFile.push(file);
                }else{
                  this.maxReviewFileError=true;
                  this.reviewErrMsg=constantVariables.REVIEWIMAGEMSG;
                }
            }else{
              this.maxReviewFileError=true;
              this.reviewErrMsg=constantVariables.IMAGETYPEMSG;
            }
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          this.maxReviewFileError=true;
          this.reviewErrMsg=constantVariables.IMAGETYPEMSG;
        }
      }
    }else{
      this.maxReviewFileError=true;
      this.reviewErrMsg=constantVariables.REVIEWIMAGEMSG;
    }
  }


  //** Remove The Selected Review Images  **/
  removeReviewImage(i, item){
    const index = this.reviewFileUrl.indexOf(i);
    if (item && item.id){
      this.reviewFileDeleteUrl.push(item.id)
    }
    this.reviewFileUrl.splice(i, 1);
  }
  
   /**
  * Reset Review Form
  */
  resetForm()
  {
    this.modalRef.hide();
    this.reviewFormErr=false;
    this.reviewCommentsError=false;
    this.maxReviewFileError=false;
    this.reviewForm.reset();
    this.reviewFileUrl=[];
    this.reviewFile=[];
    this.reviewFiles=[];
  }
}
