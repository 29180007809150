import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PoolServiceDetails } from 'src/app/_models/pool-service-details.model';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ListserviceService {

 	constructor(private http: HttpClient) { }
    /**
     * Api call for getting all states list 
     */
  	statesList() {
        
        var url = `${environment.apiUrl}/api/statesList`;

        return this.http.get<any>(url)
            .pipe(map(data => {

                return data;
            }));
    }
    /**
     * Api call for getting all cities list
     * @param stateId
     */
    citiesList(stateId) {
        
        var url = `${environment.apiUrl}/api/citiesList/${stateId}`;

        return this.http.get<any>(url)
            .pipe(map(data => {

                return data;
            }));
    }
    /**
     * Save api call for pool service creation 
     * @param poolServiceData 
     * @param userdata 
     */
    savePoolService(poolServiceData,userdata){
        if (poolServiceData.poolId){
            var url = `${environment.apiUrl}/api/updatePoolService`;
        }else{
            var url = `${environment.apiUrl}/api/savePoolService`;
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'multipart/form-data',
                'Authorization':'Bearer ' + localStorage.currentUser
            }),
            observe: 'response' as 'body'
        };
        const formData = new FormData();
        formData.append('poolId', poolServiceData.poolId);
        formData.append('street',poolServiceData.locationData.street);
        formData.append('stateSelected',poolServiceData.locationData.stateSelected);
        if(poolServiceData.descriptionData.coverImage && poolServiceData.descriptionData.coverImage[0]){
            formData.append('coverImage',poolServiceData.descriptionData.coverImage[0]);
        }
        formData.append('citySelected',poolServiceData.locationData.citySelected);
        formData.append('country',poolServiceData.locationData.country);
        formData.append('zipCode',poolServiceData.locationData.zipCode);
        formData.append('serviceName',poolServiceData.descriptionData.serviceName);
       // formData.append('serviceProvided',poolServiceData.descriptionData.serviceProvided);
        formData.append('serviceDescription',poolServiceData.descriptionData.serviceDescription);
        formData.append('poolOpening',poolServiceData.poolData.poolOpening);
        formData.append('poolClosing',poolServiceData.poolData.poolClosing);
        formData.append('maintenance',poolServiceData.poolData.maintenance); 
        formData.append('poolOpeningType',poolServiceData.poolData.poolOpeningType);
        formData.append('poolClosingType',poolServiceData.poolData.poolClosingType);
        formData.append('maintenanceType',poolServiceData.poolData.maintenanceType);
        formData.append('cancelPolicy',poolServiceData.poolData.cancelPolicy);
        formData.append('userId',userdata.id);
        var serviceImages=poolServiceData.descriptionData.serviceImages;
        for (var x = 0; x < serviceImages.length; x++) {
            formData.append("serviceImages[]", serviceImages[x]);
        }
        var serviceFileDeleteUrl = poolServiceData.serviceFileDeleteUrl;
        for (var x = 0; x < serviceFileDeleteUrl.length; x++) {
            formData.append("serviceFileDeleteUrl[]", serviceFileDeleteUrl[x]);
        }
        var serviceProvided = poolServiceData.descriptionData.serviceProvided;
        for (var x = 0; x < serviceProvided.length; x++) {
            formData.append("serviceProvided[]", serviceProvided[x]);
        }
        return this.http.post<any>(url, formData,  httpOptions ) .pipe(map(data => {
                return data;
        }));
    }

    /**
     * poolServiceDetails 
     */
    poolServiceDetails(poolId){
        var url = `${environment.apiUrl}/api/poolServiceDetails/${poolId}`;

        return this.http.get<any>(url)
            .pipe(map(data => {

                return data;
            }));
    }
}
