import { Component, OnInit ,TemplateRef} from '@angular/core';
import {ManagemybookingService} from 'src/app/_services/managemybooking.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl,FormArray } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { constantVariables } from 'src/scripts/constants';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/_services/alert.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Timeconvert } from 'src/app/_pipes/app.timeConvert';
@Component({
  selector: 'app-managemybooking',
  templateUrl: './managemybooking.component.html',
  styleUrls: ['./managemybooking.component.scss']
})
export class ManagemybookingComponent implements OnInit {

submitted = false;
loading = false;
tabledata=[];
bookingTime=[];
modalRef: BsModalRef;
config = {
  backdrop: true,
  ignoreBackdropClick: true
};
id:string;
bookingSearchForm: FormGroup;
searchText:{};
customerUserType = constantVariables.CUSTOMERUSERTYPE;
spUserType = constantVariables.SPUSERTYPE;
gridBookingStatus = constantVariables.BOOKINGSTATUS;
currentUser={};
currentUserType:number;
bookingSearch={searchText:''};
userType:{};
userId:{};
showEmptyMessage=false;
searchingBook=false;
isLoadingShow=true;
currentUserData:[];
timeString:string;
reviewData=[];
reviewButton:[{null}];
  constructor(private manageMyBookingService:ManagemybookingService,private route: ActivatedRoute,private formBuilder: FormBuilder,
  private router: Router,private sanitizer: DomSanitizer, private alertService: AlertService, private modalService: BsModalService) { }

  ngOnInit() {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUserType=JSON.parse(localStorage.getItem('currentUser')).user_type;
      this.bookingSearchForm = this.formBuilder.group({
        searchText: [''],
      });
      this.userId = JSON.parse(localStorage.getItem('currentUser')).id;
      this.userType = JSON.parse(localStorage.getItem('currentUser')).user_type;
      this.searchingBook = false;
      this.getBookinglist(this.searchText);
      localStorage.removeItem('bookingDetails');
    }

  get f() { return this.bookingSearchForm.controls; }

  openForgetModal(template: TemplateRef<any>,id) {
      this.id=id;
      this.modalRef = this.modalService.show(template,this.config);
    }
  /** to get Booking List*/
  getBookinglist(searchText){
    this.loading = this.isLoadingShow?true:false;

    var currentUserData ={id:this.userId,usertype:this.userType};
    this.manageMyBookingService.bookingList(searchText,currentUserData)
      .pipe(first())
      .subscribe(
          data => {
            this.loading = false;
            if(data.status){
              this.tabledata=data.result.bookingList;
              this.showEmptyMessage = this.tabledata.length<1?true:false;
              this.isLoadingShow = true;
            }
          },
          error => {
              this.loading = false;
    });
  }
  /** To Delete Booking**/
  deleteBooking(){
   this.modalRef.hide();
   this.submitted = true;
   this.loading = true;
    this.manageMyBookingService.deleteBooking(this.id)
      .pipe(first())
      .subscribe(
          data => {
            window.scrollTo(0, 0)
            if(data.status){
                this.removeBooking(this.id);
                  this.alertService.success(data.message, true);
                  this.submitted = false;
                  this.loading = false;
            }
          },
          error => {
              this.loading = false;
    });
  }
  /** Remove The Deleted Data From Array**/
  removeBooking(id){
    this.tabledata = this.tabledata.filter(item => item.id !== id);
  }
  /**search form submit  */
  onSubmit(){
    this.submitted=true;
    if(this.bookingSearchForm.invalid){
      return;
    }
    this.getBookinglist(this.bookingSearchForm.value.searchText);  
  }
  searchBooking(){
    this.searchingBook=true;
    this.isLoadingShow=false;
    this.getBookinglist(this.bookingSearchForm.value.searchText);  
  }
  
  /**
   * To view booking details
   */
  viewBooking(poolDetail,type){
    console.log(poolDetail);
    var cencelPolicys={};
    constantVariables.CANCELPOLICIES.forEach(element => {
      if ((element.id) === parseInt(poolDetail.pool_service_details.cancelPolicy)) {
         cencelPolicys = element;
      }
    });
    this.reviewData=poolDetail.review_status;
    var data = {
      // bookingDate: poolDetail.bookingDate,
      // bookingTime: poolDetail.bookingTime,
      // services: JSON.parse(poolDetail.bookingServices),
      // notes: poolDetail.bookingNotes,
      // poolId: poolDetail.poolServiceId, poolName: poolDetail.pool_service_details.serviceName,
      // poolOpening: poolDetail.pool_service_details.poolOpening, poolClosing: poolDetail.pool_service_details.poolClosing,
      // maintenance: poolDetail.pool_service_details.maintenance,
      // cancelPolicy: cencelPolicys,
      // bookingId: poolDetail.bookingId,
       userId: poolDetail.userId,
      // statusId: poolDetail.booking_status.id,
      // bookingStatus: poolDetail.booking_status, poolManage: type, 
      manageBookingId: poolDetail.id,
      poolManage: type
      // poolOpeningType: poolDetail.pool_service_details.poolOpeningType,
      // poolClosingType: poolDetail.pool_service_details.poolClosingType,
      // maintenanceType: poolDetail.pool_service_details.maintenanceType,
      // paymentStatus: poolDetail.paymentStatus,
      // paymentMode: poolDetail.paymentMode,bookingAmount:poolDetail.bookingAmount,
      // Review:this.reviewData,poolServiceTypes:poolDetail.pool_service_type_id,commissionPercentage:poolDetail.pool_service_details.commission
    };
    localStorage.setItem('bookingDetails', JSON.stringify(data));
    this.router.navigate(['bookservice']);
  }


  getEditOption(data,type){
    var editEnable=false;
    if (data.booking_status.id !== this.gridBookingStatus[0].id && type===2){
      return false;
    }
    if (this.currentUser && this.currentUserType === this.customerUserType){
        if(data.booking_status.id === this.gridBookingStatus[0].id){
          editEnable=true;
        }
    }else{
      if ((data.booking_status.id === this.gridBookingStatus[0].id) 
        || data.booking_status.id === this.gridBookingStatus[1].id
        || data.booking_status.id === this.gridBookingStatus[2].id){
        editEnable = true;
      }
    }
    return editEnable;
  }
}
