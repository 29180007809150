import { Component, OnInit, HostListener, Inject, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

import { User } from 'src/app/_models/user.model';

import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    
    appTitle = "FindDoctor";
    addClassToShowNav : boolean = false;
    addClassToDropdown : boolean = false;
    isScrollHeader = false;

    currentUser: User;
    apiUrl: string;
    userImg: string;
    defaultImg : string;


    constructor(private router: Router, private authenticationService: AuthenticationService, private renderer: Renderer2) {
                this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                if(event.url != '/'){
                    this.isScrollHeader = true;
                }else{
                    this.isScrollHeader = false;
                }
            }

            if (event instanceof NavigationEnd) {
                console.log(event.url);
                if (event.url != '/' && event.url != '/account/login' && event.url != '/register'){
                    this.isScrollHeader = true;
                }else{
                    this.isScrollHeader = false;
                }

            }
        });

    }
    /**
     * Handles window scroll event
     * @param e 
     */
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        if(!this.isScrollHeader) {
            if (window.pageYOffset > 300) {
               let element = document.getElementById('navbar');
               element.classList.add('scroll_header');
            } else {
              let element = document.getElementById('navbar');
                element.classList.remove('scroll_header');
            }
        }
    }
    /**
     * Handles initial asignment of variables
     */
    ngOnInit() {
            this.apiUrl = `${environment.apiUrl}`;
            this.defaultImg = '/assets/images/default-user-profile-image.png';
                if(this.currentUser){
                let usr = this.currentUser;
                if(usr.profile_img != '' || usr.profile_img != null){
                    this.userImg = this.apiUrl+usr.profile_img;
                }else{
                    this.userImg = '/assets/images/default-user-profile-image.png';
                }
                }

    }
    /**
     * Handles menu show in header section 
     */
    toogleSideNav() {
        this.addClassToShowNav = !this.addClassToShowNav;
        let body = document.getElementsByTagName('body')[0];
        if(this.addClassToShowNav){
            body.classList.add("body_freeze");
        }else{
            body.classList.remove("body_freeze");
        }
    }
    /**
     * Handles menu show in header section
     * @param a 
     */
    toogleDropdown(a){
        if(this.addClassToDropdown == a){
            this.addClassToDropdown = !this.addClassToDropdown;
        }else{
            this.addClassToDropdown = a;
        }

    }
    /**
     * Handles user logout from application
     */
    logout(){
        localStorage.removeItem('mapDirectionDestination')
        localStorage.removeItem('searchItem')
        localStorage.removeItem('bookingDetails');
        this.authenticationService.logout();
        this.router.navigate(['/account/login']);
    }
    callBookAService(){
        this.toogleSideNav();
        this.router.navigate(['/']);
        window.scroll(1, 230);
    }
}
