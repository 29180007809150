import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }
  /**
   * Handles authorize  payment request call 
   */
  paymentCheckout(paymentData) {
    var url = `${environment.apiUrl}/api/authorize/checkout`;
    return this.http.post<any>(url, paymentData)
      .pipe(map(data => {
        return data;
      }));
  }
}
