import { Component, OnInit,TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { AlertService } from 'src/app/_services/alert.service';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/_models/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  editForm: FormGroup;
	loading = false;
	user = {};
	imageChangedEvent: any = '';
    croppedImage: any = '';
    modalRef: BsModalRef;
    config = {
      backdrop: true,
      ignoreBackdropClick: true
    };
    isScrollHeader: true;
    apiUrl: string;
    userImg : string;
    type: string;
    editProfile = false;
    submitted = false;
    currentUser={};
    currentUserId:number;
  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router, private alertService: AlertService, private userService: UserService,private modalService: BsModalService) { }
  /**
   * Initial assignment of form,variables
   */
  ngOnInit() {
    this.apiUrl = `${environment.apiUrl}`;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
      this.type = this.route.snapshot.paramMap.get("type");
      if(this.type == 'edit'){
        this.editProfile = true;
      }else{
        this.editProfile = false;
      }

  	console.log('CurrentUser',this.currentUser)
  	this.loading = true;
    this.userService.getUser(this.currentUserId)
            .pipe(first())
            .subscribe(
                data => {
                  this.loading = false;
                  console.log(data);
                  if(data.status){
                  		var a = data.result.user;
                      if(a != '') {
                  		if(a.profile_img != ''){
                        this.userImg = this.apiUrl+a.profile_img;
                      }else{
                        this.userImg = '/assets/images/default-user-profile-image.png';
                      }
                      this.user = a;
                      }
                      
                       // this.alertService.success(data.message);                         
                  }else{
                        this.alertService.error(data.message);
                  }
                    
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });

      this.editForm = this.formBuilder.group({
            first_name: ['', [Validators.required,
              Validators.maxLength(20)]],
            last_name: ['', [Validators.required,
              Validators.maxLength(20)]],
            phone: ['', [Validators.required,
              Validators.pattern('^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$')]],
            bio: []
        });

  }

  get f() { return this.editForm.controls; }
  /**
   * Handles profile form validation and submit
   */
  onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.editForm.invalid) {

            return;
        }
        this.loading = true;
        this.userService.update(this.editForm.value)
            .pipe(first())
            .subscribe(
                data => {
                  
                  if(data.status){
                    this.alertService.success(data.message, true);
                    this.submitted = false;
                    this.loading = false;
                    setTimeout(() => {
                      this.router.navigate(['profile']);
                    }, 2000);
                  }else{
                    this.submitted = false;
                    this.loading = false;
                    this.alertService.error(data.message, true);
                  }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    /**
     * Triggers file upload 
     */
  changePhoto(){
    document.getElementById('file_input').click();
  }
    
    /**
     * Handles file change event 
     * @param event 
     */
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        
    }
    /**
     * Handles image crop event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    /**
     * Triggers image loading in crop  
     */
    imageLoaded() {
        document.getElementById('test_evt').click();
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    /**
     * Handles crop failure
     */
    loadImageFailed() {
        document.getElementById('modal_close').click();
        this.alertService.error('Invalid Format');
        // show message
    }
    /**
     * Handles image upload of user account
     */
    imgUpload(){
      this.userImg = this.croppedImage;
        document.getElementById('modal_close').click();
        this.loading = true;
        this.userService.imgUpload(this.croppedImage)
            .pipe(first())
            .subscribe(
                data => {
                  this.alertService.success(data.result.message, true);
                  //  this.submitted = false;
                    this.loading = false;
                  var a = data.result.user;
                  var user =this.currentUser;
                  console.log(a)
                  if (a != '') {
                    if (a.profile_img != '') {
                      this.userImg = this.apiUrl+'/profile/' + a.profile_img;
                    } else {
                      this.userImg = '/assets/images/default-user-profile-image.png';
                    }
                    this.user = a;
                  }
                    this.userImage(user,a.profile_img);
                    // window.location.reload();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    userImage(user,image)
    {
      user.profile_img='/profile/'+image;
      localStorage.setItem('currentUser',JSON.stringify(user));
    }
    

}
