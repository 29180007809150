import { Component, OnInit } from '@angular/core';
import {ListserviceService} from 'src/app/_services/listservice.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl,FormArray } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { constantVariables } from 'src/scripts/constants';
import { AlertService } from 'src/app/_services/alert.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-listmyservice',
  templateUrl: './listmyservice.component.html',
  styleUrls: ['./listmyservice.component.scss']
})
export class ListmyserviceComponent implements OnInit {
  locationForm:FormGroup;
  descriptionForm:FormGroup;
  poolForm:FormGroup;
  paymentForm:FormGroup;
  locationData={country:1,street:'',stateSelected:null,citySelected:null,zipCode:''};
  descriptionData={serviceName:'',serviceProvided:[],serviceDescription:'',coverImage:[],serviceImages:[]};
  poolData={};
  paymentData={};
  currentUser={};
  firstStep = false;
  secondStep = false;
  thirdStep = false;
  fourthStep = false;
  fifthStep = false;
  loading = false;
  submitted = false;
  descriptionSubmitted=false;
  poolSubmitted=false;
  paymentSubmitted=false;
  firstStepCompleted=false;
  secondStepCompleted=false;
  thirdStepCompleted=false;
  fourthStepCompleted=false;
  maxFilesError=false;
  maxServiceFileError=false;
  serviceProvidedErrors=false;
  cancelPolicyError=false;
  poolOpeningTypeError=false;
  poolClosingTypeError=false;
  maintenanceTypeError=false;
  termsAndConditionErr=false;
  poolOpeningRequiredErr=false;
  poolClosingRequiredErr=false;
  maintenanceRequiredErr=false;
  states=[];
  cities=[];
  citiesValue=[];
  fileUrl=[]
  serviceFileUrl=[]
  coverFile=[];
  serviceFile=[];
  serviceProviders=constantVariables.SERVICEPROVIDERS;
  errorMsg="";
  serviceErrMsg="";
  cancelPolicies=constantVariables.CANCELPOLICIES;
  poolOpeningValErr=false;
  poolClosingValErr=false;
  poolMaintenanceValErr=false;
  serviceFinalUrl = [];
  serviceFileDeleteUrl=[];
  poolOpeningType = constantVariables.BOOKINGTYPEOPTIONS;
  poolClosingType = constantVariables.BOOKINGTYPEOPTIONS;
  maintenanceType = constantVariables.BOOKINGTYPEOPTIONS;
  poolId:number;
  currentUserId:number;
  constructor(private ListserviceService:ListserviceService,private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer, private alertService: AlertService) {
  }

  ngOnInit() {
    this.currentUser=JSON.parse(localStorage.getItem('currentUser'));
    this.poolId = parseInt(this.route.snapshot.paramMap.get("poolId"));
    if (this.currentUser){
      this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
    }
    this.goToNextStep(1);
    this.getStates();
    this.locationForm = this.formBuilder.group({
      street: ['',  [Validators.required,Validators.maxLength(200)]],
      citySelected: ['',[Validators.required]],
      stateSelected: ['', [Validators.required]],
      zipCode: ['', [Validators.required ,Validators.maxLength(5)]],
      country:['', [Validators.required]],
    });
     this.descriptionForm = this.formBuilder.group({
      serviceName: ['', [Validators.required,Validators.maxLength(20)]],
      serviceProvided:this.formBuilder.array([], Validators.compose([Validators.required])),
      serviceDescription: ['', [Validators.required,Validators.maxLength(1000)]],
    });
     this.poolForm = this.formBuilder.group({
      poolOpening: ['', [Validators.maxLength(10)]],
      poolClosing: ['', [Validators.maxLength(10)]],
      maintenance: ['', [Validators.maxLength(10)]],
      cancelPolicy:[''],
      poolOpeningType:[''],
      poolClosingType:[''],
      maintenanceType:[''],
    });

    this.paymentForm = this.formBuilder.group({
      termsAndCondition: ['', [Validators.required]],
    });
    if(this.poolId){
      this.getPoolServiceDetails()
    }
    console.log("services3",this.serviceProviders)
  }

  get ld() { return this.locationForm.controls; }
  get dd() { return this.descriptionForm.controls; }
  get pd() { return this.poolForm.controls; }
  get pay() { return this.paymentForm.controls; }

  /** To navigate to next step  */
  goToNextStep(val){
    switch(val){
      case 1:
        this.firstStep=true;
        this.secondStep = false;
        this.thirdStep = false;
        this.fourthStep = false;
        this.fifthStep = false;
        break;
      case 2:
        this.secondStep = true;
        this.firstStep = false;
        this.thirdStep = false;
        this.fourthStep = false;
        this.fifthStep = false;
        this.getchecked();
        break;
      case 3:
        this.thirdStep = true;
        this.firstStep = false;
        this.secondStep = false;
        this.fourthStep = false;
        this.fifthStep = false;
        break;
      case 4:
        this.fourthStep = true;
        this.firstStep = false;
        this.secondStep = false;
        this.thirdStep = false;
        this.fifthStep = false;
        break;
      case 5:
        this.fifthStep = true;
        this.firstStep = false;
        this.secondStep = false;
        this.thirdStep = false;
        this.fourthStep = false;
        break;
      default:
        this.firstStep = false;
        this.secondStep = false;
        this.thirdStep = false;
        this.fourthStep = false;
        this.fifthStep = false;
    }
  }

  /** to get state and city of USA*/
  getStates(){
    this.loading = true;
    this.ListserviceService.statesList()
      .pipe(first())
      .subscribe(
          data => {
            this.loading = false;
            if(data.status){
              this.states=data.result.states;
            }
          },
          error => {
              this.loading = false;
    });
  }
   /** to get city of US*/
  getCities(stateId){
    this.loading = true;
    this.ListserviceService.citiesList(stateId)
      .pipe(first())
      .subscribe(
          data => {
            this.loading = false;
            if(data.status){
              this.cities=data.result.cities;
            }
          },
          error => {
              this.loading = false;
    });
  }
/**
 * Get cities list
 */
  getCityValues(){
    this.getCities(this.locationForm.value.stateSelected)
  }

  /** location form submit **/
  onSubmit(val){
    this.submitted = true;
    if (this.locationForm.invalid) {
      return;
    }
    this.locationData={country:this.locationForm.value.country,
    street:this.locationForm.value.street,
    citySelected:this.locationForm.value.citySelected,
    stateSelected:this.locationForm.value.stateSelected,
    zipCode:this.locationForm.value.zipCode}
    this.firstStepCompleted=true;
    this.goToNextStep(val)
  }

  /** */
  cancelPage(){
    this.router.navigate(['']);
  }

   /** description form submit **/
  onDescriptionSubmit(val){
    console.log('serviceProvided',this.descriptionForm.value.serviceProvided)
    this.descriptionSubmitted = true;
    if(this.descriptionForm.value && this.descriptionForm.value.serviceProvided.length< constantVariables.CONSTONE){
      this.serviceProvidedErrors=true;
    }
    if (!this.fileUrl || this.fileUrl.length<= constantVariables.CONSTZERO){
      this.maxFilesError=true;
      this.errorMsg=constantVariables.IMAGEREQMSG;
    }
    if (!this.serviceFileUrl || this.serviceFileUrl.length<= constantVariables.CONSTZERO){
      this.maxServiceFileError=true;
      this.serviceErrMsg=constantVariables.IMAGEREQMSG;
    }
    if (this.serviceFileUrl.length > constantVariables.CONSTZERO && this.serviceFileUrl.length <= constantVariables.CONSTTEN){
      this.maxServiceFileError = false;
    }
    if (this.descriptionForm.invalid) {
      return;
    }


    if (!this.serviceProvidedErrors && !this.maxFilesError && !this.maxServiceFileError){
      this.descriptionData={
      serviceName:this.descriptionForm.value.serviceName,
      serviceProvided:this.descriptionForm.value.serviceProvided,
      serviceDescription:this.descriptionForm.value.serviceDescription,
      coverImage:this.coverFile,serviceImages:this.serviceFile}
      this.secondStepCompleted=true;
      this.goToNextStep(val)
    }
  }


  /**
   * Handles drag and drop of cover image  file
   */
  public files: NgxFileDropEntry[] = [];
  public serviceFiles: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.maxFilesError=false;
    this.fileUrl=[];
    this.files=[];
    this.coverFile=[];
    if(this.files && this.files.length<constantVariables.CONSTONE){
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if(file.type==='image/png' || file.type==='image/jpeg' || file.type==='image/jpg' || file.type==='image/gif'){
              const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
              if(this.files && this.files.length<1){
                this.fileUrl.push(url);
                this.files.push(droppedFile);
                this.coverFile.push(file);
              }else{
                this.maxFilesError=true;
                this.errorMsg=constantVariables.COVERIMAGEMSG;
              }
            }else{
              this.maxFilesError=true;
              this.errorMsg=constantVariables.IMAGETYPEMSG;
            }
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          this.maxFilesError=true;
          this.errorMsg=constantVariables.IMAGETYPEMSG;
        }
      }
    }else{
      this.maxFilesError=true;
      this.errorMsg=constantVariables.COVERIMAGEMSG;
    }

  }
  /**
   * Handles drag and drop of service iamge  file
   */

  public serviceFileDropped(files: NgxFileDropEntry[]) {
     this.maxServiceFileError=false;
    if (this.serviceFileUrl && this.serviceFileUrl.length<10){
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if(file.type==='image/png' || file.type==='image/jpeg' || file.type==='image/jpg' || file.type==='image/gif'){
                const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
              if (this.serviceFileUrl && this.serviceFileUrl.length < constantVariables.CONSTTEN){
                  this.serviceFiles.push(droppedFile );
                  this.serviceFileUrl.push({ url: url});
                  this.serviceFile.push(file);
                }else{
                  this.maxServiceFileError=true;
                  this.serviceErrMsg=constantVariables.SERVICEIMAGEMSG;
                }
            }else{
              this.maxServiceFileError=true;
              this.serviceErrMsg=constantVariables.IMAGETYPEMSG;
            }
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          this.maxServiceFileError=true;
          this.serviceErrMsg=constantVariables.IMAGETYPEMSG;
        }
      }
    }else{
      this.maxServiceFileError=true;
      this.serviceErrMsg=constantVariables.SERVICEIMAGEMSG;
    }
  }


  //** Remove The Selected Cover Image **/
  removeCoverImage(item){
    const index = this.fileUrl.indexOf(item);
    this.fileUrl.splice(index, 1);
  }
  //** Remove The Selected Service Images  **/
  removeServiceImage(i, item){
    const index = this.serviceFileUrl.indexOf(i);
    if (item && item.id){
      this.serviceFileDeleteUrl.push(item.id)
    }
    this.serviceFileUrl.splice(i, 1);
  }

/**
 * Handles service provided checked or unchecked status
 * @param option
 * @param event
 */

  updateCheckedOptions(option, event) {
      const isChecked= event.target.checked;
      const serviceProvidedArray = <FormArray>this.descriptionForm.controls.serviceProvided;
      if(isChecked) {
        this.serviceProvidedErrors=false;
        serviceProvidedArray.push(new FormControl(option.id));
      } else {
        let i: number = 0;
          serviceProvidedArray.controls.forEach((ctrl: FormControl) => {
            if(ctrl.value == option.id) {
              var uncheckd= this.serviceProviders.find(item => item.id === option.id);
              uncheckd.checked = false;
              serviceProvidedArray.removeAt(i);
              return;
            }
            i++;
          });
      }
    }
    /**
     * Updates checked status on back or next step navigation
     */
    getchecked(){
      for(var i=0;i<this.serviceProviders.length;i++){
        if(this.serviceProviders && this.descriptionData.serviceProvided && this.descriptionData.serviceProvided.length>0 &&
        this.descriptionData.serviceProvided.indexOf(this.serviceProviders[i].id) !== -1) {
          this.serviceProviders[i].checked = true;
        }
        //else
        // {
        //   this.serviceProviders[i].checked = false;
        // }
      }
      
    }
    /** third step starts here*/
    onPoolSubmit(val){

      this.poolSubmitted = true;
      this.cancelPolicyError=false;
      this.poolOpeningTypeError=false;
      this.poolClosingTypeError=false;
      this.maintenanceTypeError=false;
      this.poolOpeningRequiredErr=false;
      this.poolClosingRequiredErr=false;
      this.maintenanceRequiredErr=false;

     
      if(this.poolForm.value && !this.poolForm.value.cancelPolicy){
        this.cancelPolicyError=true;
      }  
       this.descriptionForm.value.serviceProvided.forEach(data => {
       if(this.poolForm.value && !this.poolForm.value.poolOpening && data==1)
      {
        this.poolOpeningRequiredErr=true;
      }
      if(this.poolForm.value && !this.poolForm.value.maintenanceType && data==2)
      {
        this.maintenanceRequiredErr=true;
      }
      if(this.poolForm.value && !this.poolForm.value.poolClosing && data==3)
      {
        this.poolClosingRequiredErr=true;
      }
      if(this.poolForm.value && !this.poolForm.value.poolOpeningType && data==1){
        this.poolOpeningTypeError=true;
      }     
      if(this.poolForm.value && !this.poolForm.value.maintenanceType && data==2){
        this.maintenanceTypeError=true;
      }
      if(this.poolForm.value && !this.poolForm.value.poolClosingType && data==3){
        this.poolClosingTypeError=true;
      }   
    });

      

      if (this.poolForm.invalid) {
        return;
      }
      if(!this.poolOpeningRequiredErr && !this.poolClosingRequiredErr && !this.maintenanceRequiredErr && !this.cancelPolicyError && !this.poolOpeningTypeError && !this.poolClosingTypeError && !this.maintenanceTypeError && !this.poolOpeningValErr && !this.poolClosingValErr
      && !this.poolMaintenanceValErr){
        this.poolData={
          poolOpening:this.poolForm.value.poolOpening,
          poolClosing:this.poolForm.value.poolClosing,
          maintenance:this.poolForm.value.maintenance,
          cancelPolicy:this.poolForm.value.cancelPolicy,
          poolOpeningType:this.poolForm.value.poolOpeningType,
          poolClosingType:this.poolForm.value.poolClosingType,
          maintenanceType:this.poolForm.value.maintenanceType},
          this.thirdStepCompleted=true;
          this.goToNextStep(val)
      }
    }

    /** pool value validation **/
    validateNumber(type,event){
      this.descriptionForm.value.serviceProvided.forEach(data => {
        if(type===constantVariables.CONSTONE && data==1){
          this.poolOpeningValErr=event.target.value< constantVariables.CONSTONE ? true: event.target.value>constantVariables.BILLION ?true :false;
        }
        if(type=== constantVariables.CONSTTHREE && data==2){
          this.poolMaintenanceValErr = event.target.value < constantVariables.CONSTONE ? true : event.target.value > constantVariables.BILLION ?true :false;
        }
        if(type=== constantVariables.CONSTTWO && data==3){
          this.poolClosingValErr = event.target.value < constantVariables.CONSTONE ? true : event.target.value > constantVariables.BILLION ?true :false;
        }
        
      });
    }

    /** Fourth step starts  **/
    onPaymentSubmit(val){
      this.termsAndConditionErr=false;
      this.paymentSubmitted = true;
      if (this.paymentForm.invalid) {
        return;
      }
      if (this.paymentForm.value.termsAndCondition === true || this.paymentForm.value.termsAndCondition === 'true'){
          this.loading = true;
          var data={locationData:this.locationData,
          descriptionData:this.descriptionData,poolData:this.poolData,
            poolId: this.poolId, serviceFileDeleteUrl: this.serviceFileDeleteUrl
          }
          this.ListserviceService.savePoolService(data,this.currentUser)
            .pipe(first())
            .subscribe(
                data => {
                  this.loading = false;
                  console.log('listmyservice',data);
                  if (data.status === 200 && data.body && data.body.code===constantVariables.CONSTONE){
                    this.goToNextStep(val)
                    console.log('nextStep');
                  }else{
                    let msg = data.body.result && data.body.result.message ? data.body.result.message : data.body.message;
                    this.alertService.error(data.body.result.message);
                    console.log('msg');
                    window.scrollTo(0,0);
                  }
                },
                error => {
                    this.loading = false;
                  this.alertService.error(constantVariables.APIRESPONSEERR);
                  window.scrollTo(0, 0);
          });

      }else{
        this.termsAndConditionErr=true;
      }
    }

    /**
     * get pool service details
     */
    getPoolServiceDetails(){
      this.loading = true;
      this.ListserviceService.poolServiceDetails(this.poolId)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            if (data.status) {
              this.fileUrl = [];
              this.serviceFileUrl=[];
              var result = data.result.poolDetails[0];
              if (result.createdBy === this.currentUserId){
                this.locationData = { country: constantVariables.COUNTRYID, street: result.street, stateSelected: result.stateId
                  , citySelected: result.cityId, zipCode:result.zipCode };
                this.getCities(result.stateId)
                this.descriptionData = { serviceName:result.serviceName, serviceProvided: result.pool_service_type_id,
                  serviceDescription: result.serviceDescription, coverImage: [], serviceImages: [] };
                this.poolData = {poolOpening:result.poolOpening,poolClosing:result.poolClosing,
                  maintenance: result.maintenance, cancelPolicy: result.cancelPolicy, poolOpeningType: result.poolOpeningType,
                  poolClosingType: result.poolClosingType, maintenanceType: result.maintenanceType};
                this.paymentData = { termsAndCondition:true};
                this.paymentForm.value.termsAndCondition=true;
                this.fileUrl.push(environment.apiUrl + constantVariables.COVERIMAGEPATH+result.coverImage);
                result.poolserviceimages.forEach(item => {
                  this.serviceFinalUrl.push(item.id)
                  this.serviceFileUrl.push({ url: environment.apiUrl + constantVariables.SERVICEIMAGEPATH + item.poolServiceImage, id: item.id});
                });
                for (var i = 0; i < this.serviceProviders.length; i++) {
                  let index = this.descriptionData.serviceProvided.find(record => record.poolServiceType === (this.serviceProviders[i].id));
                  if (index){
                    this.serviceProviders[i].checked = true;
                    const serviceProvidedArray = <FormArray>this.descriptionForm.controls.serviceProvided;
                    serviceProvidedArray.push(new FormControl(index.poolServiceType));
                }
              }
            }else{
                this.router.navigate(['error'])
            }
          }
          },
          error => {
            this.loading = false;
          });
    }
}
