import { Component, OnInit,TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AlertService } from 'src/app/_services/alert.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    forgetForm: FormGroup;
  	loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    foccused: number | string;
    email: string;
    activation_token: string;
    activation_mail: string;
    type: string;
    modalRef: BsModalRef;
    config = {
      backdrop: true,
      ignoreBackdropClick: true
    };
    isScrollHeader: true;
  redirectUrl:string;
  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router, private authenticationService: AuthenticationService,private alertService: AlertService, private modalService: BsModalService) { 
  		

        }

  /**
   * Opens forgot password modal popup
   * @param template 
   */
    openForgetModal(template: TemplateRef<any>) {
        this.forgetForm.reset();
        this.modalRef = this.modalService.show(template,this.config);
    }
    /**
     * page initial assignment and desclarations
     */
    ngOnInit() {
  
      this.activation_token = this.route.snapshot.paramMap.get("token");
      this.activation_mail = this.route.snapshot.paramMap.get("mail");
      this.type = this.route.snapshot.paramMap.get("type");
     
      if(this.activation_token != '' && this.activation_mail!= '' && this.type == 'activate'){
      
        this.loading = true;
        this.authenticationService.activate(this.activation_token, this.activation_mail)
            .pipe(first())
            .subscribe(
                data => {
                  this.loading = false;
                  if(data.status){
                        this.alertService.success(data.message);                         
                  }else{
                        this.alertService.error(data.message);
                  }
                    
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
      }

      
  		this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required,
              Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            password: ['', Validators.required]
        });

      this.forgetForm = this.formBuilder.group({
            email: ['', [Validators.required,
              Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  

  // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get forget() { return this.forgetForm.controls; }
    /**
     * Handles login form validation and submit
     */
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.submitted = false;
                    this.loading = false;
                    if(data.status){
                      var url = this.returnUrl;
                      this.redirectUrl = localStorage.getItem('redirectUrl');
                      if (this.redirectUrl){
                        url = this.redirectUrl;
                      }else{
                        url = this.returnUrl;
                      }
                      localStorage.removeItem('redirectUrl');
                      this.router.navigate([url]);
                    }
                    else{
                      this.alertService.error(data.message, true);
                      
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    /**
     * Handles forgot password validation and submit
     */
    onForgetSubmit() {
        this.submitted = true;

        if (this.forgetForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.forgetpwd(this.forgetForm.value)
            .pipe(first())
            .subscribe(
                data => {
                  this.submitted = false;
                  this.loading = false;
                  if(data.status){
                    this.alertService.success(data.message, true);
                    this.forgetForm.reset();
                  }
                  else{
                    this.alertService.error(data.message, true);
                    this.forgetForm.reset();
                  }
                   
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

}
