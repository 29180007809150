import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { constantVariables } from 'src/scripts/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { BookingsService } from 'src/app/_services/bookings.service';
import { ListserviceService } from 'src/app/_services/listservice.service';
import { UserService } from 'src/app/_services/user.service';
import { NullTemplateVisitor } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Timeconvert } from 'src/app/_pipes/app.timeConvert';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PaymentService } from 'src/app/_services/payment.service';
@Component({
  selector: 'app-bookservice',
  templateUrl: './bookservice.component.html',
  styleUrls: ['./bookservice.component.scss']
})
export class BookserviceComponent implements OnInit {
  book: FormGroup;
  booking: FormGroup;
  bookingForm: FormGroup;
  statusForm: FormGroup;
  reviewForm: FormGroup;
  paymentForm: FormGroup;
  submitted = false;
  public imagePath;
  imgURL: any;
  imageChangedEvent: any = '';
  currentUser={}
  accountData = { first_name: '', last_name: '', email: '', phone: '', street_1: '', street_2: '', state: '', city: '', country: '', postal_code: '', };
  termsError=false;
  loading=false;
  showThankyoupage=false;
  states=[];
  cities=[];
  countryName = constantVariables.COUNTRY;
  bookingDetails = { poolId: '', manageBookingId: '', statusId: '', bookingDate: '', bookingTime: '', notes: '', services: [], poolOpening: null, maintenance: null, poolClosing: null, userId: null, poolManage: 0, bookingId: '', bookingStatus: {}, Review: [], poolServiceTypes: [], commissionPercentage: null, poolName: '', cancelPolicy: {}, poolOpeningType: '', poolClosingType: '', maintenanceType: '', paymentStatus: '', paymentMode: '', bookingAmount:''};
  totalAmount: Number=constantVariables.CONSTONE;
  disableForm=false;
  currentUserId:number;
  customerUserType = constantVariables.CUSTOMERUSERTYPE;
  spUserType = constantVariables.SPUSERTYPE;
  gridBookingStatus = constantVariables.BOOKINGSTATUS;
  dollarValue = constantVariables.DOLLAR;
  popupMessage='';
  username='';
  profilePic='';
  profile_image='';
  fullname='';
  showCalculation=false;
  cancelId:number;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  poolOpeningHours: number = 1;
  poolClosingHours: number = 1;
  poolMaintenanceHours: number = 1;
  serviceProviders = constantVariables.SERVICEPROVIDERS;
  bookingData = { bookingTime: null, bookingDate: null, services: [], notes: '' }
  totalBookingAmount: Number = constantVariables.CONSTONE;
  searchData = { statusValue: '' }
  totalAmountErr = false;
  changedStatus: number;
  servicesErr = false;
  currentUserType: number;
  todayDate = new Date();
  dateErr = false;
  timeErr = false;
  isStatusChanged=false;
  id='';
  notesErr = false;
  changedStatusVal = { id: null };
  test='';
  reviewData=[];
  reviewButton:boolean;
  reviewImage=[];
  maxReviewFileError=false;
  reviewFormErr=false;
  reviewCommentsError=false;
  reviewErrMsg="";
  reviewFileUrl=[];
  reviewFile=[];
  reviewFileDeleteUrl=[];
  reviewSubmitted=false;
  expirationYearNumber = constantVariables.EXPYEAR;
  expirationYear = [];
  expirationMonth = constantVariables.EXPMONTH;
  expirationDay = [];
  paySubmit = false;
  showPaymentThankyoupage = false;
  currency = constantVariables.CURRENCY;
  spEmail = '';
  paymentData = { cardNumber: '', expiryYear: '', expiryMonth: '', expiryDay: '', cvv: '' };
  checkBox=[];
  
  constructor(private sanitizer: DomSanitizer,private userService: UserService, private ListserviceService: ListserviceService, private formBuilder: FormBuilder, private BookingsService: BookingsService, private route: ActivatedRoute, private router: Router, private alertService: AlertService, private modalService: BsModalService,
    private PaymentService: PaymentService) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.reviewButton=false;
    if (this.currentUser) {
     this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
       this.currentUserType = JSON.parse(localStorage.getItem('currentUser')).user_type;
       this.profilePic = JSON.parse(localStorage.getItem('currentUser')).profile_img;
       this.fullname = JSON.parse(localStorage.getItem('currentUser')).full_name;
    }
    let bookServiceDetails = JSON.parse(localStorage.getItem('bookingDetails'));
    this.id = this.route.snapshot.paramMap.get("bookingId");
    if (this.id) {
      this.decryptValue(this.id)
    }
    else {
      if (bookServiceDetails && bookServiceDetails.bookingNew){
        this.bookingDetails = bookServiceDetails;
        this.setBookingValues();
      }else{
        this.getBookingDetails(bookServiceDetails.manageBookingId);
      }
    }
    if (!this.currentUser) {
      this.disableForm = true;
      this.accountData = { first_name: '', last_name: '', email: '', phone: '', street_1: '', street_2: '', state: '', city: '', country: '', postal_code: '' };
    }
    //create expiration year
    for (var i = constantVariables.EXPSTARTYEAR; i <= this.expirationYearNumber; i++) {
      let years = { id: i, value: i };
      this.expirationYear.push(years);
    }
    for (var i = 1; i <= 31; i++) {
      var days = { id: i, value: i };
      this.expirationDay.push(days);
    }
    this.statusForm = this.formBuilder.group({
      statusValue: ['']
    });
    this.bookingForm = this.formBuilder.group({
      bookingDate: ['', [Validators.required]],
      bookingTime: ['', [Validators.required]],
      services: this.formBuilder.array([]),
      notes: ['']
    });
    this.booking = this.formBuilder.group({
      firstname: [{ value: '', disabled: this.disableForm }, [Validators.required, Validators.maxLength(15)]],
      lastname: [{ value: '', disabled: this.disableForm }, [Validators.required, Validators.minLength(2)]],
      email: [{ value: '', disabled: true }, [Validators.required]],
      // cemail: [{ value: '', disabled: this.disableForm }, [ Validators.required]],
      telephone: [{ value: '', disabled: this.disableForm }, [Validators.required, Validators.minLength(10), Validators.maxLength(12)]],
      country: [{ value: '', disabled: this.disableForm }, [Validators.required]],
      street_1: [{ value: '', disabled: this.disableForm }, [Validators.required, Validators.maxLength(20)]],
      street_2: [{ value: '', disabled: this.disableForm }, [Validators.maxLength(20)]],
      city: [{ value: '', disabled: this.disableForm }, [Validators.required]],
      state: [{ value: '', disabled: this.disableForm }, [Validators.required]],
      terms: [{ value: true, disabled: this.disableForm }, [Validators.required]],
      postal_code: [{ value: '', disabled: this.disableForm }, [Validators.required, Validators.maxLength(5)]]
    });
    this.paymentForm = this.formBuilder.group({
      cardNumber: ['', [Validators.required, Validators.maxLength(16), Validators.pattern("^[0-9]*$"),]],
      expiryYear: ['', [Validators.required]],
      expiryMonth: ['', [Validators.required]],
      // expiryDay: ['', [Validators.required]],
      cvv: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"),]],
    });

     this.reviewForm = this.formBuilder.group({
      comments:['', [Validators.required,Validators.maxLength(1000)]],
      currentRate:['', [Validators.required]]
    });

    if (this.currentUser) {
      var userId = bookServiceDetails && bookServiceDetails.poolManage ? bookServiceDetails.userId : this.currentUserId;
      this.getUserDetails(userId);
      this.getStates();
    }
  }
  get ab() { return this.bookingForm.controls; }
  get kv() { return this.booking.controls; }
  get f() { return this.reviewForm.controls; }
  get ps() { return this.paymentForm.controls; }


  /**
   * get user details
   */
  getUserDetails(userId) {
    this.loading = true;
    this.userService.getUser(userId)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            var a = data.result.user;
            if (a != '') {
              this.accountData = a;
              var address = data.result.address;
              if (address && address[0]) {
                this.accountData.street_1 = address[0].streetLine1;
                this.accountData.street_2 = address[0].streetLine2;
                this.accountData.state = address[0].stateId;
                this.accountData.city = address[0].cityId;
                this.accountData.country = address[0].countryId;
                this.accountData.postal_code = address[0].zipCode;
                this.getCities(address[0].stateId)
              }
            }
          } else {
            this.alertService.error(data.message);
          }

        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }
  /** to get state and city of USA*/
  getStates() {
    this.loading = true;
    this.ListserviceService.statesList()
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.states = data.result.states;
          }
        },
        error => {
          this.loading = false;
        });
  }

  /** to get city of US*/
  getCities(stateId) {
    this.loading = true;
    this.ListserviceService.citiesList(stateId)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.cities = data.result.cities;
          }
        },
        error => {
          this.loading = false;
        });
  }
  /**
   * Handles change on state
   */
  getCityValues() {
    this.getCities(this.booking.value.state)
  }
  /**
   * Handles booking service
   */
  bookService() {
    if (this.currentUser) {
      this.submitted = true;
      this.termsError = false;
      if (!this.booking.value.terms) {
        this.termsError = true;
      }
      if (this.booking.invalid) {
        return;
      }
      this.loading = true;
      this.BookingsService.bookService(this.booking.value, this.bookingDetails, this.totalAmount, this.currentUser)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            if (data && data.code === constantVariables.CONSTONE) {
              this.showThankyoupage = true;
              this.serviceProviders.forEach(item => {
                item.checked = false;
              });
              localStorage.removeItem('bookingDetails');
            } else {
              this.alertService.error(constantVariables.APIRESPONSEERR);
            }
          },
          error => {
            this.loading = false;

            this.alertService.error(constantVariables.APIRESPONSEERR);
          });
    }

  }

  /**
   * Redirect user to login page
   */
  goToLogin() {
    var url = 'bookservice';
    localStorage.setItem('redirectUrl', url);
    this.router.navigate(['account/login']);
  }
  /**
   * check status
   */
  checkStatusConfirm(statusData, template) {
    this.showCalculation = false;
    this.changedStatusVal = this.gridBookingStatus.find(record => record.id == this.statusForm.value.statusValue)
    this.changedStatus = this.statusForm.value.statusValue;
    if (parseInt(this.statusForm.value.statusValue) === constantVariables.CONSTTHREE) {
      this.popupMessage = 'Are you sure the service is completed?';
      this.searchData = { statusValue: this.statusForm.value.statusValue };
      this.modalRef = this.modalService.show(template, this.config);
    }
    else if (parseInt(this.statusForm.value.statusValue) === constantVariables.CONSTFOUR && statusData.paymentMode !== constantVariables.CONSTONE) {
      this.popupMessage = 'The payment has been made offline.Are you sure want to close this booking?';
      this.modalRef = this.modalService.show(template, this.config);
      this.searchData = { statusValue: this.statusForm.value.statusValue };
    } else {
      this.changedStatus = this.statusForm.value.statusValue;
      this.searchData = { statusValue: this.statusForm.value.statusValue };
    }
    this.isStatusChanged = true;

  }
  /**
   * changeStatus
   */
  confirmedComplete(statusData) {
    this.changedStatus = this.statusForm.value.statusValue;
    console.log('searchData',this.searchData)
    this.showCalculation = true;

    this.modalRef.hide()
  }
  changeStatus(statusData) {
    var data = { manageBookingId: statusData.manageBookingId, status: this.statusForm.value.statusValue }
    this.loading = true;
    this.BookingsService.changeBookingStatus(data)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            this.alertService.success(data.result.message);
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }
  /**
   * cancelBooking
   */

  openModalPopup(template: TemplateRef<any>, id) {
    this.cancelId = id;
    this.popupMessage = 'Are you sure want to cancel?';
    this.modalRef = this.modalService.show(template, this.config);
  }
  /**
   * Make payment
   */
  openPaymentPopup(template: TemplateRef<any>, id) {
    this.popupMessage = 'Make Payment';
    this.paymentForm.reset();
    this.paySubmit = false;
    this.modalRef = this.modalService.show(template, this.config);
  }

  /**
   * Write A Review
   */
   writeReviewPopup(template: TemplateRef<any>, id){
    this.popupMessage = 'Rate & Review';
    this.reviewForm.reset();
     this.reviewFileUrl=[];
    this.username =  this.fullname;
    if(this.profilePic != '' && this.profilePic != null){
        this.profile_image =environment.apiUrl+this.profilePic;
    }else{
        this.profile_image = '/assets/images/default-user-profile-image.png';
    }
    this.modalRef = this.modalService.show(template, this.config);
  }

  cancelBooking(bookingDetails){
    /*this.popupMessage='Are you sure want to cancel?';*/
    var data = { manageBookingId: this.cancelId }
    this.loading = true;
    this.BookingsService.cancelBooking(data)
      .pipe(first())
      .subscribe(
        data => {
          window.scrollTo(0, 0)
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            this.alertService.success(data.result.message);
            bookingDetails.statusId = constantVariables.CANCELSTATUS[0].id;
            bookingDetails.bookingStatus = constantVariables.CANCELSTATUS[0];
            // localStorage.removeItem('bookingDetails');
            // localStorage.setItem('bookingDetails', JSON.stringify(bookingDetails));
            this.modalRef.hide();
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

  /**
 * Adjust booking details value assignment
 */

  assignBookingValues() {
    if (this.bookingDetails) {
      this.bookingData = {
        bookingTime: this.bookingDetails.bookingTime,
        bookingDate: this.bookingDetails.bookingDate,
        notes: this.bookingDetails.notes,
        services: this.bookingDetails.services,
      }
      for (var i = 0; i < this.serviceProviders.length; i++) {
        let index = this.bookingDetails.services.findIndex(record => record.id === this.serviceProviders[i].id);
        if (index >= 0 && this.serviceProviders && this.bookingDetails.services && this.bookingDetails.services.length > 0 &&
          this.bookingDetails.services[index].id === this.serviceProviders[i].id) {
          this.serviceProviders[i].checked = true;
          const serviceProvidedArray = [];//<FormArray>this.bookingForm.controls.services;
          serviceProvidedArray.push(new FormControl(this.serviceProviders[i]));
        } else {
          this.serviceProviders[i].checked = false;
        }
      }
    } else {
      this.serviceProviders.forEach(item => {
        item.checked = false;
      });
    }

  }

  updateBooking(bookingDetails, status) {
    this.submitted = true;
    if (!this.bookingForm.value.bookingDate) {
      this.dateErr = true;
    } else {
      this.dateErr = false;
    }
    if (!this.bookingForm.value.bookingTime) {
      this.timeErr = true;
    } else {
      this.timeErr = false;
    }
    if (!this.bookingDetails.services || this.bookingDetails.services.length < 1) {
      this.servicesErr = true;
    } else {
      this.servicesErr = false;
    }
    if (this.bookingForm.value.notes && this.bookingForm.value.notes.length > 100) {
      this.notesErr = true;
    } else {
      this.notesErr = false;
    }
    if (!this.notesErr && !this.totalAmountErr && !this.dateErr && !this.timeErr && !this.servicesErr || bookingDetails.bookingStatus.id == this.gridBookingStatus[2].id) {
      var bookData = {
        manageBookingId: bookingDetails.manageBookingId,
        bookingDate: this.bookingForm.value.bookingDate ? this.bookingForm.value.bookingDate : bookingDetails.bookingDate,
        bookingTime: this.bookingForm.value.bookingDate ? this.bookingForm.value.bookingTime : bookingDetails.bookingTime,
        notes: this.bookingForm.value.bookingDate ? this.bookingForm.value.notes : bookingDetails.notes,
        bookingAmount: this.totalAmount, status: this.changedStatus, services: this.bookingDetails.services
      }
      this.loading = true;
      var bookDatas = bookingDetails;
      console.log('booking',bookDatas)
      this.BookingsService.updateBooking(bookData)
        .pipe(first())
        .subscribe(
          data => {
            window.scrollTo(0, 0)
            this.loading = false;
            if (data && data.code === constantVariables.CONSTONE) {
              this.alertService.success(data.result.message);
              this.isStatusChanged = false;
              bookingDetails.bookingStatus = this.changedStatusVal && this.changedStatusVal.id ? this.changedStatusVal : status;
              bookingDetails.statusId = this.changedStatus ? this.changedStatus : status.id;
              bookingDetails.notes = bookData.notes;
              bookingDetails.bookingDate = bookData.bookingDate;
              bookingDetails.bookingTime = bookData.bookingTime;
              bookingDetails.services = bookData.services;
              // localStorage.removeItem('bookingDetails');
              // localStorage.setItem('bookingDetails', JSON.stringify(bookingDetails));
            } else {
              this.alertService.error(constantVariables.APIRESPONSEERR);
            }
          },
          error => {
            this.loading = false;
            this.alertService.error(constantVariables.APIRESPONSEERR);
          });
    }
  }

  /*
  getPrice
  */
  getPrice(data) {
    var price = 0;
    constantVariables.SERVICEPROVIDERS.forEach(item => {
      if (item.id === constantVariables.CONSTONE && item.id === data.id) {
        price = this.bookingDetails.poolOpening * this.poolOpeningHours;

        return price;
      }
      else if (item.id === constantVariables.CONSTTWO && item.id === data.id) {
        price = this.bookingDetails.maintenance * this.poolMaintenanceHours;
        return price;
      }
      else if (item.id === constantVariables.CONSTTHREE && item.id === data.id) {
        price = this.bookingDetails.poolClosing * this.poolClosingHours;
        return price;
      }
    });
    data.price = price;
    return price;
  }

  /**
   * hoursChange 
   */
  hoursChange(event, data) {
    if (event.target.value > 0) {
      this.totalAmountErr = false;
      if (data.id === constantVariables.CONSTONE) {
        this.poolOpeningHours = event.target.value;
        this.getPrice(data);
      }
      else if (data.id === constantVariables.CONSTTWO) {
        this.poolMaintenanceHours = event.target.value;
        this.getPrice(data);
      }
      else if (data.id === constantVariables.CONSTTHREE) {
        this.poolClosingHours = event.target.value;
        this.getPrice(data);
      }
      data.hours = event.target.value;
      this.getTotalBookingAmount()
    } else {
      this.totalAmountErr = true;
    }
  }
  /**
   * get total bookin
   */
  getTotalBookingAmount() {
    var totlAmt: number = 0;
    var installValues: string = '0';
    let maintenances: string = '0';
    let Repairs: string = '0';
    this.bookingDetails.services.forEach(item => {

      if (item.name === 'Installation') {
        var subTotal = item.hours ? item.hours * this.bookingDetails.poolOpening : this.bookingDetails.poolOpening * this.poolOpeningHours;
        totlAmt = totlAmt + (subTotal);
      }
      else if (item.name === 'Maintenence') {
        var subTotal = item.hours ? item.hours * this.bookingDetails.maintenance : this.bookingDetails.maintenance * this.poolMaintenanceHours;

        totlAmt = totlAmt + (subTotal) ;
      }
      else if (item.name === 'Repair') {
        var subTotal = item.hours ? item.hours * this.bookingDetails.poolClosing : this.bookingDetails.poolClosing * this.poolClosingHours;
        totlAmt = totlAmt + (subTotal);
      }
    });
    this.totalAmount = totlAmt;
    return totlAmt;
  }

  commissionDeduct()
  {
    var totlAmt: number = 0;
    var installValues: string = '0';
    let maintenances: string = '0';
    let Repairs: string = '0';
    this.bookingDetails.services.forEach(item => {

      if (item.name === 'Installation') {
        var subTotal = item.hours ? item.hours * this.bookingDetails.poolOpening : this.bookingDetails.poolOpening * this.poolOpeningHours;
        totlAmt = totlAmt + (subTotal);
      }
      else if (item.name === 'Maintenence') {
        var subTotal = item.hours ? item.hours * this.bookingDetails.maintenance : this.bookingDetails.maintenance * this.poolMaintenanceHours;

        totlAmt = totlAmt + (subTotal) ;
      }
      else if (item.name === 'Repair') {
        var subTotal = item.hours ? item.hours * this.bookingDetails.poolClosing : this.bookingDetails.poolClosing * this.poolClosingHours;
        totlAmt = totlAmt + (subTotal);
      }
    });
    this.totalAmount = totlAmt;
    if(this.bookingDetails.commissionPercentage!= null)
    {
    return totlAmt-totlAmt*this.bookingDetails.commissionPercentage/100;  
    }
    return totlAmt;
  }

  enableBookingEdit() {
    this.bookingDetails.poolManage = 2;
    this.disableForm = this.currentUserType === this.spUserType ? true : false;

  }
  /**
   * check text box show
   */
  checkTextBoxShow(data, bookingDetails) {
    var displayText = false
    if (data.name === 'Installation' && bookingDetails.poolOpeningType === constantVariables.CONSTTWO) {
      displayText = true;
    }
    else if (data.name === 'Maintenence') {
      displayText = bookingDetails.maintenanceType === constantVariables.CONSTTWO ? true : false;
    }
    else if (data.name === 'Repair' && bookingDetails.poolClosingType === constantVariables.CONSTTWO) {
      displayText = true;
      return displayText;
    }
    return displayText;
  }

  /**
   * form disable check
   */
  checkFormDisable(booking) {
    if (booking.bookingStatus.id === 3 || booking.bookingStatus.id === 4) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * check edit option
   * @param data
   * @param type
   */
  getEditOption(data, type) {
    var editEnable = false;
    if (this.currentUser && this.currentUserType === this.customerUserType) {
      if (data.bookingStatus.id === this.gridBookingStatus[0].id) {
        editEnable = true;
      }
    } else {
      if ((data.bookingStatus.id === this.gridBookingStatus[0].id)
        || data.bookingStatus.id === this.gridBookingStatus[1].id
        || data.bookingStatus.id === this.gridBookingStatus[2].id) {
        editEnable = true;
      }
    }
    return editEnable;
  }

  /**
   * cancel status change
   */
  cancelStatus(bookingDetails) {
    this.changedStatus = bookingDetails.bookingStatus.id;
    this.searchData = { statusValue: bookingDetails.bookingStatus.id };
    this.isStatusChanged = false;
    this.modalRef.hide()
    console.log('searchData',this.searchData)
  }

  /**
   * Handles dropdown multi selet
   *
   */
  updateCheckedOptions(option, event) {
    const isChecked = event.target.checked;
    const serviceProvidedArray = <FormArray>this.bookingForm.controls.services;
    if (isChecked) {
      this.servicesErr = false;
      this.bookingDetails.services.push(option)
      serviceProvidedArray.push(new FormControl(option));
    } else {
      let i: number = 0;
      var inde = this.bookingDetails.services.findIndex(record => record.id == option.id);
      this.bookingDetails.services.splice(inde, 1);
      serviceProvidedArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value.id == option.id) {
          serviceProvidedArray.removeAt(i);

          return;
        }
        i++;
      });
    }
  }
  /**
   * get booking details
   */
  getBookingDetails(id) {
    /*this.popupMessage='Are you sure want to cancel?';*/
    this.loading = true;
    this.BookingsService.bookingDetails(id)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            if (this.currentUserType === this.customerUserType && data.result && data.result.bookingDetails && data.result.bookingDetails.userId !== this.currentUserId) {
              this.router.navigate(['error'])
            } else {
              var poolDetail = data.result.bookingDetails;
              var cencelPolicys = {};
              constantVariables.CANCELPOLICIES.forEach(element => {
                if ((element.id) === parseInt(poolDetail.pool_service_details.cancelPolicy)) {
                  cencelPolicys = element;
                }
              });
              this.reviewData = poolDetail.review_status;
              let storedData=JSON.parse(localStorage.getItem('bookingDetails'));
              this.bookingDetails = {
                bookingDate: poolDetail.bookingDate,
                bookingTime: poolDetail.bookingTime,
                services: JSON.parse(poolDetail.bookingServices),
                notes: poolDetail.bookingNotes,
                poolId: poolDetail.poolServiceId, 
                poolName: poolDetail.pool_service_details.serviceName,
                poolOpening: poolDetail.pool_service_details.poolOpening, poolClosing: poolDetail.pool_service_details.poolClosing,
                maintenance: poolDetail.pool_service_details.maintenance,
                cancelPolicy: cencelPolicys,
                bookingId: poolDetail.bookingId, userId: poolDetail.userId, statusId: poolDetail.booking_status.id,
                bookingStatus: poolDetail.booking_status, poolManage: storedData.poolManage ? storedData.poolManage:1, manageBookingId: poolDetail.id,
                poolOpeningType: poolDetail.pool_service_details.poolOpeningType,
                poolClosingType: poolDetail.pool_service_details.poolClosingType,
                maintenanceType: poolDetail.pool_service_details.maintenanceType,
                paymentStatus: poolDetail.paymentStatus,
                paymentMode: poolDetail.paymentMode, bookingAmount: poolDetail.bookingAmount,
                Review: this.reviewData, poolServiceTypes: poolDetail.pool_service_type_id, commissionPercentage: poolDetail.pool_service_details.commission
              }
              // localStorage.setItem('bookingDetails', JSON.stringify(bookingFetchData));
              this.setBookingValues();
            }
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

  /**
  * Rating And Review
  **/
  review(form)
  {
    this.reviewSubmitted = true;
    this.maxReviewFileError = false;
    this.reviewFormErr=false;
    this.reviewCommentsError = false;
    if (!this.reviewFileUrl || this.reviewFileUrl.length<= constantVariables.CONSTZERO){
      this.maxReviewFileError=true;
      this.reviewErrMsg=constantVariables.IMAGEREQMSG;
    }
    if (this.reviewFileUrl.length <= constantVariables.CONSTZERO){
      this.maxReviewFileError = true;
      this.reviewErrMsg = 'Please upload image';
    }
    if (!this.reviewForm.value.comments){
      this.reviewCommentsError=true;
    }
    if(!this.reviewForm.value.currentRate)
    {
      this.reviewFormErr=true;
    }
    if (this.reviewForm.invalid) {
      return;
    }
    if(!this.maxReviewFileError && !this.reviewFormErr)
    {
      this.loading = true;
     var data ={poolId:this.bookingDetails.poolId,BookingId: this.bookingDetails.manageBookingId,userId:this.currentUserId, comments:this.reviewForm.value.comments,currentRate:this.reviewForm.value.currentRate,file:this.reviewFile,reviewFileDeleteUrl: this.reviewFileDeleteUrl}
      this.BookingsService.userReview(data)
    .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE || data.body.code === constantVariables.CONSTONE) {
            window.scrollTo(0, 0)
            this.alertService.success(data.body.result.message);
          }else{
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
          this.modalRef.hide();
        },
        error => {
          this.loading = false;
        });
    }
  }


  /**
   * Handles drag and drop of review image  file
   */
  public files: NgxFileDropEntry[] = [];
  public reviewFiles: NgxFileDropEntry[] = [];

  /**
   * Handles drag and drop of service iamge  file
   */

  public reviewFileDropped(files: NgxFileDropEntry[]) {
     this.maxReviewFileError=false;
    if(this.reviewFiles && this.reviewFiles.length<5){
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if(file.type==='image/png' || file.type==='image/jpeg' || file.type==='image/jpg' || file.type==='image/gif'){
                const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
                if(this.reviewFiles && this.reviewFiles.length<5){
                  this.reviewFiles.push(droppedFile );
                  this.reviewFileUrl.push({ url: url});
                  this.reviewFile.push(file);
                }else{
                  this.maxReviewFileError=true;
                  this.reviewErrMsg=constantVariables.REVIEWIMAGEMSG;
                }
            }else{
              this.maxReviewFileError=true;
              this.reviewErrMsg=constantVariables.IMAGETYPEMSG;
            }
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          this.maxReviewFileError=true;
          this.reviewErrMsg=constantVariables.IMAGETYPEMSG;
        }
      }
    }else{
      this.maxReviewFileError=true;
      this.reviewErrMsg=constantVariables.REVIEWIMAGEMSG;
    }
  }


  //** Remove The Selected Review Images  **/
  removeReviewImage(i, item){
    const index = this.reviewFileUrl.indexOf(i);
    if (item && item.id){
      this.reviewFileDeleteUrl.push(item.id)
    }
    this.reviewFileUrl.splice(i, 1);
  }


  /**
   * on payment form submit
   */
  onPaymentSubmit(bookingDetail) {
    this.paySubmit = true;
    if (this.paymentForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = this.paymentForm.value;
    var payData = {
      cardNumber: formData.cardNumber,
      expiryYear: formData.expiryYear, expiryMonth: formData.expiryMonth,
      cvv: formData.cvv, manageBookingId: bookingDetail.manageBookingId,
      checkoutAmount: bookingDetail.bookingAmount, userId: this.currentUserId
    }
    this.PaymentService.paymentCheckout(payData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            bookingDetail.paymentStatus = constantVariables.CONSTONE;
            bookingDetail.paymentMode=constantVariables.CONSTONE;
           // localStorage.setItem('bookingDetails', JSON.stringify(bookingDetail));
            this.spEmail = data.result.userEmail;
            this.modalRef.hide()
            this.showPaymentThankyoupage = true;
          } else {
            this.alertService.error(data.result.message);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

  /**
   * Close payment Popup
   */
  closePaymentPopup() {
    this.modalRef.hide()
    this.paymentForm.reset();
    this.paySubmit = false;
  }

  /**
  * get decrypted value
  */
  decryptValue(id) {
    this.loading = true;
    this.userService.fetchValue(id)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            this.getBookingDetails(data.result.decryptedVal)
          } else {
            this.alertService.error(data.result.message);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

  /**
   * set values from storage
   */
  setBookingValues() {
  
    console.log("BookingDate",this.bookingDetails.bookingDate)
    console.log('commissionPercentage',this.bookingDetails.commissionPercentage)
    if (this.bookingDetails) {
      var arealistArray=[];
              this.bookingDetails.poolServiceTypes.forEach((elem1, index) => {elem1;
                this.serviceProviders.forEach((elem2, index) => {elem2;
                  if(elem1.poolServiceType=== elem2.id)
                  {
                    arealistArray.push(elem2);
                  }
                });
              });
              this.checkBox=arealistArray;
      if (this.bookingDetails.Review && this.bookingDetails.Review.length == 0) {
        this.reviewButton = true;
      } else if (this.bookingDetails.Review && this.bookingDetails.Review[0].reviewStatus == 2) {
        this.reviewButton = true;
      } else {
        this.reviewButton = false;
      }
      var installValue: string = '0';
      let maintenance: string = '0';
      let Repair: string = '0';
      this.bookingDetails.services.forEach(item => {
        if (item.name === 'Installation') {
          installValue = item.hours ? item.hours * this.bookingDetails.poolOpening : this.bookingDetails.poolOpening;
        }
        if (item.name === 'Maintenence') {
          maintenance = item.hours ? item.hours * this.bookingDetails.maintenance : this.bookingDetails.maintenance;
        }
        if (item.name === 'Repair') {
          Repair = item.hours ? item.hours * this.bookingDetails.poolClosing : this.bookingDetails.poolClosing;
        }
      });
      this.disableForm = this.bookingDetails.poolManage === constantVariables.CONSTONE || this.currentUserType === this.spUserType ? true : false;
      this.searchData.statusValue = this.bookingDetails.statusId;
      this.changedStatus = parseInt(this.bookingDetails.statusId);
      this.showCalculation = parseInt(this.bookingDetails.statusId) === this.gridBookingStatus[2].id ? true : false;
      this.totalAmount = parseFloat(installValue) + parseFloat(maintenance) + parseFloat(Repair);
      this.totalBookingAmount = this.totalAmount;
      this.reviewData = this.bookingDetails.Review;
      this.assignBookingValues()
    } else {
      this.router.navigate(['poollist']);
    }
  }
  /**
   * check status disable option
   */
  checkStatusDisable(bookingDetails, status) {
    if (status.id === bookingDetails.bookingStatus.id) {
      return true;
    } else if ((bookingDetails.bookingStatus.id === 3 || bookingDetails.bookingStatus.id === 4) && (status.id === 1 || status.id === 2)) {
      return true;
    } else if ((bookingDetails.bookingStatus.id === 4) && (status.id === 1 || status.id === 2 || status.id === 3 )) {
       return true;
     }
    else {
      return false;
    }
  }
  /**
  * Reset Review Form
  */
  resetForm()
  {
    this.modalRef.hide();
    this.reviewFormErr=false;
    this.reviewCommentsError=false;
    this.maxReviewFileError=false;
    this.reviewForm.reset();
    this.reviewFileUrl=[];
    this.reviewFile=[];
    this.reviewFiles=[];
  }
}
