import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl,FormArray } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { constantVariables } from 'src/scripts/constants';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/_services/alert.service';
import {FootermenuService} from 'src/app/_services/footermenu.service';
@Component({
  selector: 'app-footermenu',
  templateUrl: './footermenu.component.html',
  styleUrls: ['./footermenu.component.scss']
})
export class FootermenuComponent implements OnInit {
id:string;
loading=false;
menuName:[{menuName:''}];
menuContent:[{content:''}];
  constructor(private FootermenuService:FootermenuService,private route: ActivatedRoute,private formBuilder: FormBuilder,
    private router: Router, private sanitizer: DomSanitizer, private formbuilder: FormBuilder, private alertService: AlertService) { }

  ngOnInit() {
  	this.id = this.route.snapshot.paramMap.get('id');
  	var data={id:this.id}
  	this.getFooterMenu(data);
  }

  getFooterMenu(id)
  {
  	this.loading = true;
    this.FootermenuService.footerMenu(this.id)
      .pipe(first())
      .subscribe(
          data => {
            this.loading = false;
            if(data.status){
              this.menuName=data.result.menuList[0].menuName;
              this.menuContent=data.result.menuList[0].content;
              // console.log('menu',this.menuName)
            }
          },
          error => {
              this.loading = false;
    });
  }
}
