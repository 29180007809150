import { Component, OnInit,TemplateRef } from '@angular/core';
import {ReviewsService} from 'src/app/_services/reviews.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl,FormArray } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { constantVariables } from 'src/scripts/constants';
import { AlertService } from 'src/app/_services/alert.service';
import { environment } from '../../environments/environment';
import { UserService } from 'src/app/_services/user.service';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
id:string;
reviewStatus=[];
 config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  modalRef: BsModalRef;
  popupMessage='';
  reviewData:{};
    reviewForm: FormGroup;
      reviewButton:boolean;
  reviewImage=[];
  maxReviewFileError=false;
  reviewFormErr=false;
  reviewCommentsError=false;
  reviewErrMsg="";
  reviewFileUrl=[];
  reviewFile=[];
  reviewFileDeleteUrl=[];
  reviewSubmitted=false;
  currentUser={};
  currentUserId={};
  currentUserType={};
  profilePic={};
  fullname={};
  bookingDetails={};
  poolId:number;
  loading=false;
  profile_image={};
  constructor(private userService: UserService,private ReviewsService:ReviewsService,private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer, private alertService: AlertService, private modalService: BsModalService) { }

  ngOnInit() {
  	var id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.decryptValue(id)
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.reviewButton=true;
    if(this.currentUser)
    {
      this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
       this.currentUserType = JSON.parse(localStorage.getItem('currentUser')).user_type;
       this.profilePic = JSON.parse(localStorage.getItem('currentUser')).profile_img;
       this.fullname = JSON.parse(localStorage.getItem('currentUser')).full_name;
    }
    if(this.profilePic != '' && this.profilePic != null){
        this.profile_image =environment.apiUrl+this.profilePic;
    }else{
        this.profile_image = '/assets/images/default-user-profile-image.png';
    }
  	this.checkreview(this.id);

      this.reviewForm = this.formBuilder.group({
      comments:['', [Validators.required,Validators.maxLength(1000)]],
      currentRate:['', [Validators.required]]
    });
  
  }

  get f() { return this.reviewForm.controls; }

  /**
  * get decrypted value
  */
  decryptValue(id) {
    this.loading = true;
    this.userService.fetchValue(id)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            this.id=data.result.decryptedVal;
            this.checkreview(data.result.decryptedVal)
          } else {
            this.alertService.error(data.result.message);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

  checkreview(id){
    this.ReviewsService.checkreviews(id)
      .pipe(first())
      .subscribe(
          data => {
            this.reviewStatus=data.result.reviewCheck;
            if(data.result.poolData && data.result.poolData[0])
            {
              this.poolId=data.result.poolData[0].poolServiceId;
            }
            if(this.reviewStatus.length !=0)
            {
              this.reviewData=false;
            }else
            {
              this.reviewData=true;
            }
          },
          error => {
           this.alertService.error(error);
    });
  }

  review(form)
  {
    this.reviewSubmitted = true;
    this.maxReviewFileError = false;
    this.reviewFormErr = false;
    this.reviewCommentsError = false;
    if (!this.reviewFileUrl || this.reviewFileUrl.length<= constantVariables.CONSTZERO){
      this.maxReviewFileError=true;
      this.reviewErrMsg=constantVariables.IMAGEREQMSG;
    }
    if (this.reviewFileUrl.length <= constantVariables.CONSTZERO) {
      this.maxReviewFileError = true;
      this.reviewErrMsg = 'Please upload image';
    }
    if (!this.reviewForm.value.comments) {
      this.reviewCommentsError = true;
    }
    if(!this.reviewForm.value.currentRate)
    {
      this.reviewFormErr=true;
    }
    if (this.reviewForm.invalid) {
      return;
    }
    if(!this.maxReviewFileError && !this.reviewFormErr)
    {
      this.loading = true;
     var data ={poolId:this.poolId,BookingId: this.id,userId:this.currentUserId, comments:this.reviewForm.value.comments,currentRate:this.reviewForm.value.currentRate,file:this.reviewFile,reviewFileDeleteUrl: this.reviewFileDeleteUrl} 
      this.ReviewsService.userReview(data)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.reviewButton=false;
          console.log('data',data)
          if (data && data.code === constantVariables.CONSTONE || data.body.code === constantVariables.CONSTONE) {
            window.scrollTo(0, 0)
            this.alertService.success(data.body.result.message);
            setTimeout(() => {
              this.router.navigate(['poollist']);
            }, 2000);
            
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
        });
    }
  }
   /**
   * Handles drag and drop of review image  file
   */
  public files: NgxFileDropEntry[] = [];
  public reviewFiles: NgxFileDropEntry[] = [];

  /**
   * Handles drag and drop of service iamge  file
   */

  public reviewFileDropped(files: NgxFileDropEntry[]) {
     this.maxReviewFileError=false;
    if(this.reviewFiles && this.reviewFiles.length<5){
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if(file.type==='image/png' || file.type==='image/jpeg' || file.type==='image/jpg' || file.type==='image/gif'){
                const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
                if(this.reviewFiles && this.reviewFiles.length<5){
                  this.reviewFiles.push(droppedFile );
                  this.reviewFileUrl.push({ url: url});
                  this.reviewFile.push(file);
                }else{
                  this.maxReviewFileError=true;
                  this.reviewErrMsg=constantVariables.REVIEWIMAGEMSG;
                }
            }else{
              this.maxReviewFileError=true;
              this.reviewErrMsg=constantVariables.IMAGETYPEMSG;
            }
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          this.maxReviewFileError=true;
          this.reviewErrMsg=constantVariables.IMAGETYPEMSG;
        }
      }
    }else{
      this.maxReviewFileError=true;
      this.reviewErrMsg=constantVariables.REVIEWIMAGEMSG;
    }
  }


  //** Remove The Selected Service Images  **/
  removeReviewImage(i, item){
    const index = this.reviewFileUrl.indexOf(i);
    if (item && item.id){
      this.reviewFileDeleteUrl.push(item.id)
    }
    this.reviewFileUrl.splice(i, 1);
  }

  cancelReview()
  {
    this.router.navigate(['poollist']);
  }
 
}
