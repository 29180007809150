import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    /**
     * Login method 
     * @param email 
     * @param password 
     */
    login(email: string, password: string) {

        var url = `${environment.apiUrl}/api/login`;
        var postData = {'email': email,'password': password};



        return this.http.post<any>(url, postData)
            .pipe(map(data => {
            
                // login successful if there's a jwt token in the response
                if (data && data.status) {
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(data.result.user));
                    this.currentUserSubject.next(data.result.user);
                }

                return data;
            }));
    }
    /**
     * Logout method api call 
     */
    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        return this.http.get<any>(`${environment.apiUrl}/api/logout`)
            .pipe(map(data => {
            
                // login successful if there's a jwt token in the response
                if (data && data.status) {
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                   
                }

                
            }));
        
        // remove user from local storage to log user out
        
    }

    /**
     * User Registeration api call 
     * @param user 
     */
    register(user: User) {

        var url = `${environment.apiUrl}/api/register`;

        return this.http.post<any>(url, user)
            .pipe(map(data => {

                return data;
            }));
    }
    /**
     * Handles Service Providers account registeration  
     * @param user 
     */
    spRegister(user: User) {

        var url = `${environment.apiUrl}/api/sp_register`;

        return this.http.post<any>(url, user)
            .pipe(map(data => {

                return data;
            }));
    }

/**
 * Handles forgot password for user account
 * @param user 
 */
    forgetpwd(user: User) {
        var url = `${environment.apiUrl}/api/password/forget`;

        return this.http.post<any>(url, user)
            .pipe(map(data => {

                return data;
            }));
        
    }
/**
 * Handles validation of token from user activation link
 * @param token 
 */
    validateToken(token) {
        var url = `${environment.apiUrl}/api/password/validate_token/${token}`;

        return this.http.get<any>(url)
            .pipe(map(data => {

                return data;
            }));
        
    }
/**
 * Handles user reset password 
 * @param reset 
 * @param token 
 * @param email 
 */
    resetpwd(reset,token,email) {
        var url = `${environment.apiUrl}/api/password/reset`;
        var ip = {'reset':reset,'token':token,'email':email};
        console.log(ip);
        return this.http.post<any>(url, ip)
            .pipe(map(data => {

                return data;
            }));
        
    }

/**
 * Handles user activation of account
 * @param token 
 * @param email 
 */
    activate(token: string, email: string) {
         return this.http.post<any>(`${environment.apiUrl}/api/activate_account`, {'token':token,'email': email});
        
    }


}