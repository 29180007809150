import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';


import { AlertService } from 'src/app/_services/alert.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  	registerForm: FormGroup;
    spRegisterForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    foccused: number | string;
    isScrollHeader: true;

  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router, private authenticationService: AuthenticationService,private alertService: AlertService) { 
  		

        }
  /**
   * Handles initial assignment
   */
  ngOnInit() {
  		this.registerForm = this.formBuilder.group({
            first_name: ['', [Validators.required,
              Validators.maxLength(20)]],
            last_name: ['', [Validators.required,
              Validators.maxLength(20)]],
            password: ['', [Validators.required,     
              Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9]).{7,}')
            ]],
            confirm_password: ['', Validators.required],
            email: ['', [Validators.required,
              Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            phone: ['', [Validators.required,
              Validators.maxLength(12), 
              Validators.minLength(10), 
              Validators.pattern('^[0-9]*$')]],
            bio: []
        }, {
            validator: MustMatch('password', 'confirm_password')
        });

        this.spRegisterForm = this.formBuilder.group({
            first_name: ['', [Validators.required,
              Validators.maxLength(20)]],
            last_name: ['', [Validators.required,
              Validators.maxLength(20)]],
            password: ['', [Validators.required,
              Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9]).{7,}')
            ]],
            confirm_password: ['', Validators.required],
            email: ['', [Validators.required,
              Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            phone: ['', [Validators.required,
              Validators.maxLength(12), 
              Validators.minLength(10), 
              Validators.pattern('^[0-9]*$')]],
            bio: []
        }, {
            validator: MustMatch('password', 'confirm_password')
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        
  }

  

  // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }
    get sp() { return this.spRegisterForm.controls; }
  /**
   * Handles customer registration
   */
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                  
                  if(data.status){
                    this.registerForm.reset();
                    this.alertService.success(data.message, true);
                    this.submitted = false;
                    this.loading = false;
                  }else{
                    this.submitted = false;
                    this.loading = false;
                    this.alertService.error(data.message, true);
                  }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    /**
     * Handles service provider register 
     */
    onSPSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.spRegisterForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.spRegister(this.spRegisterForm.value)
            .pipe(first())
            .subscribe(
                data => {
                  
                  if(data.status){
                    this.spRegisterForm.reset();
                    this.alertService.success(data.message, true);
                    this.submitted = false;
                    this.loading = false;
                  }else{
                    this.submitted = false;
                    this.loading = false;
                    this.alertService.error(data.message, true);
                  }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    /**
     * Handles tab click
     * @param id 
     */
    tabClick(id){
      if(id == 1){
        this.submitted = false;
        this.loading = false;
        this.registerForm.reset();
      }else{
        this.submitted = false;
        this.loading = false;
        this.spRegisterForm.reset();
      }
    }

}
