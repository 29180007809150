import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { constantVariables } from 'src/scripts/constants';
@Injectable({
  providedIn: 'root'
})
export class ListsearchService {

  constructor(private http: HttpClient) { }

	searchPool(searchData) {
    	var url = `${environment.apiUrl}/api/searchPool`;
    var searchDatas = { userId: searchData.userData ?searchData.userData.id:null,userType: searchData.userData ?searchData.userData.user_type:null, searchInput: searchData.searchInput, serviceType: searchData.serviceType, page: searchData.page, all: searchData.all,
      ratingValues: searchData.ratingValues, sortBy: searchData.sortBy,lat:searchData.lat,
      lng: searchData.lng, defaultFilter: searchData.defaultFilter};
    return this.http.post<any>(url, searchDatas)
            .pipe(map(data => {
                return data;
            }));
  }
  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    }).catch((err) => {
    })
      
  }
  getCurrentLocation(lat,lng) {
      var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + constantVariables.GOOGLEAPIKEY;
      return this.http.get<any>(url)
        .pipe(map(data => {
          return data;
        }));
  }
  setPoolFavorite(poolData) {

    var data = { poolId: poolData.poolId,
    userId: poolData.userId.id}
    var url = `${environment.apiUrl}/api/setPoolFavorite`;
    return this.http.post<any>(url, data)
      .pipe(map(data => {
        return data;
      }));
  }
  mapCoordinates(searchData) {
    var url = `${environment.apiUrl}/api/mapCoordinates`;
    // var searchDatas = { userId: searchData.userData.id, searchInput: searchData.searchInput, serviceType: searchData.serviceType, page: searchData.page, all: searchData.all };
    return this.http.post<any>(url, searchData)
      .pipe(map(data => {

        return data;
      }));
  }
  recentPoolService() {
    const url = `${environment.apiUrl}/api/recentPoolService`;
    return this.http.get<any>(url)
       .pipe(map(data => {
         return data;
       }));
 }
 footerMenus() {
    const url = `${environment.apiUrl}/api/footerMenus`;
    return this.http.get<any>(url)
       .pipe(map(data => {
         return data;
       }));
 }

}
