import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PooldetailService {

  constructor(private http: HttpClient) { }

  	poolList(id) {  		
        var url = `${environment.apiUrl}/api/poolList/${id}`;
        return this.http.get<any>(url)
            .pipe(map(data => {
                return data;
            }));
    }

    getReviewStatus(data){
      if(data.userId)
      {
      var reviewData = {poolId:data.poolId,userId:data.userId.id};  
      }else
      {
      var reviewData = {poolId: data.poolId, userId: null};
          }
    	
    	var url = `${environment.apiUrl}/api/reviewList`;
	    	return this.http.post<any>(url, reviewData)
	      .pipe(map(data => {
	        return data;
	      }));
    }
}
