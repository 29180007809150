import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { ListsearchService } from 'src/app/_services/listsearch.service';
import {constantVariables} from 'src/scripts/constants';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// declare var google: any;
// declare var google;
@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})

export class HomepageComponent implements OnInit {
    searchForm: FormGroup;
    loading = false;
    searchData = { searchInput: '', serviceType: ''};
    submitted = false;
    geoLocation = { formatted_address: '', address_components: []};
    searchInput: string;
    searchText: string;
    serviceTypes = constantVariables.SERVICEPROVIDERS;
    currentUser: {};
    coverlist = [];
    footerMenu = [];
    url = environment.apiUrl;
    imgPath = constantVariables.COVERIMAGEPATH;
    constructor(private http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private alertService: AlertService, private ListsearchService: ListsearchService, private renderer: Renderer2) { }


    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.getGeoLocation();
        this.searchForm = this.formBuilder.group({
            searchInput: [''],
            serviceType: ['']
        });
        this.recentPoolService();
        this.footerMenus();
    }
    get f() { return this.searchForm.controls; }
      // Google maps are now initialized.
    getGeoLocation() {
        this.ListsearchService.getPosition().then((position:any)=>{ 
           if(position){
            var lat = position.lat;
            var lng = position.lng;
            this.showMap(lat, lng)
            var data={lat:lat,lng:lng};
            localStorage.setItem('userCurrentLocation', JSON.stringify(data));
           } else {
               const element = this.renderer.selectRootElement('#searchInput');
               setTimeout(() => element.focus(), 0);
           }
        });
    }
    handleAddressChange(event){
        this.searchText = event.formatted_address ? event.formatted_address : event.target.value;
    }
    // search submit 
    onSubmit() {
        if (this.searchForm.value.searchInput || this.searchForm.value.serviceType) {
            let data = {
                formatted_address: this.searchForm.value.searchInput,
                addresComponent: this.geoLocation.address_components,
                serviceType: this.searchForm.value.serviceType,
                searchText: this.searchText
            }
            localStorage.setItem('searchItem', JSON.stringify(data));
            this.router.navigate(['/poollist']);
        } else {
            this.alertService.error('Please search something');
        }
    }
    carouselOptions = {
        center: true,
        items: 5,
        loop: true,        
        dots: false,
        nav    : true,
        navText : ["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 3 }
        }
    }
    /** to Show view map  */   
    showMap(lat,lng) {
        var data = {coords: true, lat:lat,lng:lng}
        this.ListsearchService.mapCoordinates(data)
            .pipe(first())
            .subscribe(
                data => {
                    if (data && data.code === constantVariables.CONSTONE) {
                        this.searchData.searchInput = data.result.geocoder.formatted_address;
                        this.searchText = data.result.geocoder.formatted_address ? data.result.geocoder.formatted_address : null;
                        const element = this.renderer.selectRootElement('#searchInput');
                        setTimeout(() => element.focus(), 0);
                    } else {
                        this.alertService.error(constantVariables.APIRESPONSEERR);
                    }
                },
                error => {
                    this.alertService.error(constantVariables.APIRESPONSEERR);
                });
    }
    /**
     * on enter call pool search method
     */
    searchService(event) {
        if (event.keyCode === 13) {
            this.onSubmit();
        }
    }
    // recent pool service
    recentPoolService() {
        this.ListsearchService.recentPoolService()
        .pipe(first())
        .subscribe(
            data => {
                if (data.status) {
                    this.coverlist = data.result;
                   // console.log(this.coverlist);
                }
            },
            error => {
                console.log('none');
            }
        );
    }
    // Footer Menus
    footerMenus() {
        this.ListsearchService.footerMenus()
        .pipe(first())
        .subscribe(
            data => {
                if (data.status) {
                    this.footerMenu = data.result.footerMenu;
                   console.log(this.footerMenu);
                }
            },
            error => {
                console.log('none');
            }
        );
    }

}
