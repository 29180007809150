import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { constantVariables } from 'src/scripts/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { UserService } from 'src/app/_services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BookingsService } from 'src/app/_services/bookings.service';
import { PaymentService } from 'src/app/_services/payment.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  loading=false;
  manageBookingId='';
  paymentForm: FormGroup;
  currentUserId: number;
  currentUserType:number;
  customerUserType = constantVariables.CUSTOMERUSERTYPE;
  spUserType = constantVariables.SPUSERTYPE;
  bookingDetails = { bookingServices: '', userId:null};
  services=[];
  currentUser={};
  dollarValue=constantVariables.DOLLAR;
  expirationYearNumber = constantVariables.EXPYEAR;
  expirationYear = [];
  expirationMonth = constantVariables.EXPMONTH;
  expirationDay = [];
  paySubmit = false;
  showPaymentThankyoupage = false;
  currency = constantVariables.CURRENCY;
  spEmail = '';
  paymentDone=false;
  paymentData = { cardNumber: '', expiryYear: '', expiryMonth: '', cvv: '' };
  constructor(private userService: UserService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private alertService: AlertService, private modalService: BsModalService,
    private BookingsService: BookingsService, private PaymentService: PaymentService
    ) { }

  ngOnInit() {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.currentUser) {
        this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
        this.currentUserType = JSON.parse(localStorage.getItem('currentUser')).user_type;
      }
      if (this.currentUserType !== this.customerUserType){
        this.router.navigate(['error'])
      }
      var id  = this.route.snapshot.paramMap.get("id");
      if (id) {
        this.decryptValue(id)
      }
      //create expiration year 
      for (var i = constantVariables.EXPSTARTYEAR; i <= this.expirationYearNumber; i++) {
        let years = { id: i, value: i };
        this.expirationYear.push(years);
      }
      for (var i = 1; i <= 31; i++) {
        var days = { id: i, value: i };
        this.expirationDay.push(days);
      }
      this.paymentForm = this.formBuilder.group({
        cardNumber: ['', [Validators.required, Validators.maxLength(16), Validators.pattern("^[0-9]*$"),]],
        expiryYear: ['', [Validators.required]],
        expiryMonth: ['', [Validators.required]],
        // expiryDay: ['', [Validators.required]],
        cvv: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"),]],
      });
    
  }
  get ps() { return this.paymentForm.controls; }
  /**
  * get decrypted value 
  */
  decryptValue(id) {
    this.loading = true;
    this.userService.fetchValue(id)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            this.manageBookingId = data.result.decryptedVal;
            this.getBookingDetails(this.manageBookingId);
          } else {
            var msg = data.result && data.result.message ? data.result.message : constantVariables.APIRESPONSEERR;
            this.alertService.error(msg);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

  /**
   * get booking Details
   */
  getBookingDetails(id) {
    this.loading = true;
    this.BookingsService.bookingDetails(id)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            if (data.result && data.result.bookingDetails && data.result.bookingDetails.userId!==this.currentUserId){
              this.router.navigate(['error'])
            }else{
              this.bookingDetails = data.result.bookingDetails;
              this.paymentDone = data.result.bookingDetails.paymentStatus===constantVariables.CONSTONE?true:false;
              this.services = JSON.parse(this.bookingDetails.bookingServices);
            }
          } else {
            this.alertService.error(constantVariables.APIRESPONSEERR);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }
  /** 
     * on payment form submit 
     */
  onPaymentSubmit(bookingDetail) {
    this.paySubmit = true;
    if (this.paymentForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = this.paymentForm.value;
    var payData = {
      cardNumber: formData.cardNumber,
      expiryYear: formData.expiryYear, expiryMonth: formData.expiryMonth,
       cvv: formData.cvv, manageBookingId: bookingDetail.id,
      checkoutAmount: bookingDetail.bookingAmount, userId: this.currentUserId
    }
    this.PaymentService.paymentCheckout(payData)
      .pipe(first())
      .subscribe(
        data => {
          window.scrollTo(0, 0)
          this.loading = false;
          if (data && data.code === constantVariables.CONSTONE) {
            this.spEmail = data.result.userEmail;
            this.showPaymentThankyoupage = true;
          } else {
            var msg = data.result && data.result.message ? data.result.message : constantVariables.APIRESPONSEERR;
            this.alertService.error(msg);
          }
        },
        error => {
          this.loading = false;
          this.alertService.error(constantVariables.APIRESPONSEERR);
        });
  }

}
